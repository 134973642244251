import { LpMultipleChoiceReport } from '@livepolls/ui-components/src/components/livepoll-question-reports/components/LpMultipleChoiceReport';
import { LpSwipeQuestionReport } from '@livepolls/ui-components/src/components/livepoll-question-reports/components/LpSwipeQuestionReport';
import { LpWordCloudReport } from '@livepolls/ui-components/src/components/livepoll-question-reports/components/LpWordCloudReport';
import { QuestionDifficultyLevel } from '@livepolls/ui-components/src/enums/question-difficulty-level.enum';
import { QuestionType } from '@livepolls/ui-components/src/enums/question-type';
import { ILiveFeedQuestionReport } from '@livepolls/ui-components/src/interfaces/livefeed-question-report';
import { IMultipleChoiceQuestionReport } from '@livepolls/ui-components/src/interfaces/multiple-choice-question-report';
import { ISwipeQuestionReport } from '@livepolls/ui-components/src/interfaces/swipe-question-report.interface';
import { TeamLeaderboardRow } from '@livepolls/ui-components/src/interfaces/team-leaderboard-row';
import { IWordCloudQuestionReport } from '@livepolls/ui-components/src/interfaces/word-cloud-report.interface';
import { CSSProperties } from 'react';
import { LiveFeedReport } from './LiveFeedReport';
interface Props {
  livePollId: number;
  livePollSessionId: number;
  questionReports: (
    | IMultipleChoiceQuestionReport
    | ILiveFeedQuestionReport
    | IWordCloudQuestionReport
    | ISwipeQuestionReport
  )[];
  isQuiz: boolean;
  responseCount: number;
  showDifficultyLevels: boolean;
  enableQuestionDifficulty: boolean;
  teamLeaderboardRows?: TeamLeaderboardRow[];
  difficultyLevelIndicatorStyle: CSSProperties;
}

export const LivePollQuestionReportList = ({
  livePollId,
  livePollSessionId,
  questionReports,
  isQuiz,
  responseCount,
  showDifficultyLevels,
  enableQuestionDifficulty,
  teamLeaderboardRows,
  difficultyLevelIndicatorStyle,
}: Props) => {
  return (
    <>
      {questionReports.map(questionReport => {
        const { id, type } = questionReport;
        if (type === QuestionType.CHOICE) {
          const choiceQuestionReport =
            questionReport as IMultipleChoiceQuestionReport;
          const difficultyLevel =
            enableQuestionDifficulty &&
            choiceQuestionReport.difficultyLevel != null
              ? choiceQuestionReport.difficultyLevel
              : QuestionDifficultyLevel.NORMAL;
          return (
            <div key={id}>
              <LpMultipleChoiceReport
                isQuiz={isQuiz}
                responseCount={responseCount}
                questionReport={questionReport as IMultipleChoiceQuestionReport}
                teamLeaderboardRows={teamLeaderboardRows}
                text={{
                  noVotes: 'No votes',
                  outOf: 'out of',
                  votes: 'votes',
                  initializing: 'Initializing',
                  noDataAvailable: 'No Data Available',
                }}
                showDifficultyLevels={showDifficultyLevels}
                difficultyLevel={difficultyLevel}
                difficultyLevelIndicatorStyle={difficultyLevelIndicatorStyle}
              />
            </div>
          );
        } else if (type === QuestionType.LIVE_FEED) {
          return (
            <LiveFeedReport
              key={id}
              livePollId={livePollId}
              livePollSessionId={livePollSessionId}
              liveFeedQuestionReport={questionReport as ILiveFeedQuestionReport}
            />
          );
        } else if (type === QuestionType.WORD_CLOUD) {
          return (
            <LpWordCloudReport
              key={id}
              wordCloudQuestionReport={
                questionReport as IWordCloudQuestionReport
              }
              text={{
                mentions: 'Mentions',
                noDataAvailable: 'No Data Available',
              }}
            />
          );
        } else if (type === QuestionType.SWIPE) {
          return (
            <LpSwipeQuestionReport
              questionReport={questionReport as ISwipeQuestionReport}
              text={{
                initializing: 'Initializing',
                noDataAvailable: 'Data not available',
              }}
              isQuiz={isQuiz}
            />
          );
        } else {
          throw new Error('Incorrect question type');
        }
      })}
    </>
  );
};
