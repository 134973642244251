interface Props {
  width: number;
  height: number;
}

export const ResponseCountSvg = ({ width, height }: Props) => {
  const image = (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 18"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.5 0.6875C11.7792 0.6875 14.4375 3.34581 14.4375 6.625C14.4375 8.05243 13.9338 9.36222 13.0944 10.3863C15.3354 11.8731 16.8125 14.4203 16.8125 17.3125H0.1875C0.1875 14.4203 1.66455 11.8731 3.90547 10.3841C3.06621 9.36222 2.5625 8.05243 2.5625 6.625C2.5625 3.34581 5.22081 0.6875 8.5 0.6875Z"
        fill="currentColor"
      />
    </svg>
  );
  return image;
};
