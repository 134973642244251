import { IGlobals } from 'src/interfaces/globals.interface';

let globals: IGlobals | null = null;

const US_DC = 'us';
const EU_DC = 'eu';
const AU_DC = 'au';
const AE_DC = 'ae';
const CA_DC = 'ca';
const STAGING = 'staging';
const DEV = 'dev';

export const setGlobals = (dc?: string) => {
  let dcSpecificUrl;
  let apiUrl;
  switch (dc) {
    case US_DC:
      dcSpecificUrl = process.env.REACT_APP_US_QP_DOMAIN;
      apiUrl = process.env.REACT_APP_US_API_URL;
      break;
    case EU_DC:
      dcSpecificUrl = process.env.REACT_APP_EU_QP_DOMAIN;
      apiUrl = process.env.REACT_APP_EU_API_URL;
      break;
    case AU_DC:
      dcSpecificUrl = process.env.REACT_APP_AU_QP_DOMAIN;
      apiUrl = process.env.REACT_APP_AU_API_URL;
      break;
    case AE_DC:
      dcSpecificUrl = process.env.REACT_APP_AE_QP_DOMAIN;
      apiUrl = process.env.REACT_APP_AE_API_URL;
      break;
    case CA_DC:
      dcSpecificUrl = process.env.REACT_APP_CA_QP_DOMAIN;
      apiUrl = process.env.REACT_APP_CA_API_URL;
      break;
    case STAGING:
      dcSpecificUrl = process.env.REACT_APP_US_QP_DOMAIN;
      apiUrl = process.env.REACT_APP_STAGING_API_URL;
      break;
    case DEV:
      dcSpecificUrl = process.env.REACT_APP_US_QP_DOMAIN;
      apiUrl = process.env.REACT_APP_DEV_API_URL;
      break;
    default:
      dcSpecificUrl = process.env.REACT_APP_US_QP_DOMAIN;
      apiUrl = process.env.REACT_APP_US_API_URL;
  }

  globals = Object.freeze({
    QP_DOMAIN: dcSpecificUrl!,
    API_URL: apiUrl!,
  });
};

export const getGlobals = (): IGlobals | null => {
  return globals;
};
