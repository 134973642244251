import { CSSProperties } from 'react';
import { QuestionDifficultyLevel } from 'src/enums/question-difficulty-level.enum';
import { IMultipleChoiceQuestionReport } from '../../../interfaces/multiple-choice-question-report';
import { TeamLeaderboardRow } from '../../../interfaces/team-leaderboard-row';
import { LpQuestionResultChartForTeams } from '../../question-result-chart-for-teams/LpQuestionResultChartForTeams';
import { LpQuestionResultChart } from '../../question-result-chart/LpQuestionResultChart';
import { LpMultipleChoiceQuestionLeaderboard } from './LpMultipleChoiceQuestionLeaderboard';
import styles from './LpMultipleChoiceReport.module.css';
import { LpQuestionTitle } from '../../question-title/LpQuestionTitle';

interface Props {
  isQuiz: boolean;
  responseCount: number;
  questionReport: IMultipleChoiceQuestionReport;
  isPreviewScreen?: boolean;
  teamLeaderboardRows?: TeamLeaderboardRow[];
  text: {
    noVotes: string;
    outOf: string;
    votes: string;
    noDataAvailable: string;
    initializing: string;
  };
  showDifficultyLevels?: boolean;
  difficultyLevel?: QuestionDifficultyLevel;
  difficultyLevelIndicatorStyle?: CSSProperties;
}

export const LpMultipleChoiceReport = ({
  isQuiz,
  responseCount,
  questionReport,
  isPreviewScreen,
  teamLeaderboardRows,
  text,
  showDifficultyLevels,
  difficultyLevel,
  difficultyLevelIndicatorStyle,
}: Props) => {
  const { answerStats, responsesByTeam } = questionReport;
  const previewScreenStyles = {
    marginLeft: 0,
  };

  const showQuestionResultChartForTeams =
    responsesByTeam && teamLeaderboardRows && teamLeaderboardRows?.length > 0;

  const showMultipleChoiceQuestionLeaderboard =
    !showQuestionResultChartForTeams && isQuiz;

  return (
    <div className={styles.chartRow} data-testid="multiple choice report">
      <LpQuestionTitle
        currentQuestionNumber={questionReport.currentQuestionNumber || 0}
        showDifficultyLevels={showDifficultyLevels}
        difficultyLevel={difficultyLevel}
        difficultyLevelIndicatorStyle={difficultyLevelIndicatorStyle}
        title={questionReport.title}
        image={questionReport.image}
      />
      <div className={styles.resultContainer}>
        {showQuestionResultChartForTeams && (
          <LpQuestionResultChartForTeams
            answerStats={answerStats}
            responsesByTeam={responsesByTeam}
            teams={createTeamsFromTeamLeaderboardRows(teamLeaderboardRows)}
            text={text}
          />
        )}
        {!showQuestionResultChartForTeams && (
          <div className={styles.chartRowInner}>
            <LpQuestionResultChart
              isQuiz={isQuiz}
              answerStats={answerStats}
              text={text}
            />
          </div>
        )}
        {showMultipleChoiceQuestionLeaderboard && (
          <div
            className={styles.questionLeaderboardColumn}
            style={isPreviewScreen ? previewScreenStyles : {}}
          >
            <LpMultipleChoiceQuestionLeaderboard
              questionReport={questionReport}
              responseCount={responseCount}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const createTeamsFromTeamLeaderboardRows = (
  teamLeaderboardRows: TeamLeaderboardRow[],
) => {
  return teamLeaderboardRows.map(teamLeaderboardRow => ({
    id: teamLeaderboardRow.teamUid,
    name: teamLeaderboardRow.teamName,
    color: teamLeaderboardRow.teamColor,
  }));
};
