import { LpQuestionTitle } from '../../question-title/LpQuestionTitle';
import { ISwipeQuestionReport } from 'src/interfaces/swipe-question-report.interface';
import styles from './LpSwipeQuestionReport.module.css';
import { LpSwipeQuestionLeaderboard } from './LpSwipeQuestionLeaderboard';
import { LpSwipeQuestionFinishedScreen } from '../../../components/swipe-question-finished/LpSwipeQuestionFinishedScreen';

interface Props {
  questionReport: ISwipeQuestionReport;
  text: { initializing: string; noDataAvailable: string };
  isQuiz: boolean;
}
export const LpSwipeQuestionReport = ({
  questionReport,
  text,
  isQuiz,
}: Props) => {
  return (
    <div className={styles.container}>
      <LpQuestionTitle
        currentQuestionNumber={questionReport.currentQuestionNumber || 0}
        title={questionReport.title || 'Untitled'}
      />
      <div className={styles.innerContainer} data-testid="swipeQuestionReport">
        <LpSwipeQuestionFinishedScreen
          isQuiz={isQuiz}
          swipeQuestion={{
            swipeOptions: (questionReport as ISwipeQuestionReport).swipeOptions,
            cards: (questionReport as ISwipeQuestionReport).cards,
          }}
          text={{
            initializing: text.initializing,
            noDataAvailable: text.noDataAvailable,
          }}
          questionResult={questionReport as ISwipeQuestionReport}
          customCardRowClass={styles.cardRow}
          customHeaderRowClass={styles.headerRow}
          customContainerClass={styles.customContainer}
        />
        {isQuiz && (
          <LpSwipeQuestionLeaderboard questionReport={questionReport} />
        )}
      </div>
    </div>
  );
};
