import { getGlobals } from 'src/globals/globals';
import { IProductUrls } from '@livepolls/ui-components/src/interfaces/product-urls.interface';

export const getProductUrls = (): IProductUrls => {
  const dcSpecificUrl = getGlobals()!.QP_DOMAIN;
  return {
    communities: `${dcSpecificUrl}/a/showPanelManagement.do`,
    customerExperience: `${dcSpecificUrl}/a/showCXFeedback.do`,
    surveys: `${dcSpecificUrl}/a/listSurveys.do`,
    workforce: `${dcSpecificUrl}/a/listMyFlashletSurveys.do`,
    audience: `${dcSpecificUrl}/a/showAudience.do`,
    insightsHub: 'https://insightshub.questionpro.com/',
    reputation: 'https://reputation.questionpro.com/',
    outerloop: 'https://outerloop.questionpro.com/',
  };
};
