import { useParams } from 'react-router-dom';
import { Header } from './components/header/Header';
import { LpFooter } from '@livepolls/ui-components/src/components/footer/LpFooter';
import styles from './dcRelativeApp.module.css';
import { setGlobals } from './globals/globals';
import { DashboardScreen } from './screens/report-dashboard/DashboardScreen';
import { TipType } from '@livepolls/ui-components/src/enums/tip-type.enum';

const locations = [
  'Austin, TX',
  'Merida, MX',
  'Pune, IN',
  'Berlin, DE',
  'London, UK',
  'Dubai',
];

const tips = [
  { type: TipType.LIFE_PRO_TIP, message: 'Press <Enter> to add Options' },
  {
    type: TipType.LIFE_PRO_TIP,
    message: 'Hit <Backspace> twice to delete an option',
  },
  {
    type: TipType.YOU_SHOULD_KNOW,
    message: 'The LivePoll tool was built by 3 engineers in 30 days',
  },
  {
    type: TipType.TODAY_I_LEARNED,
    message: 'QuestionPro has been in business for 15+ years',
  },
  {
    type: TipType.DO_YOU_KNOW,
    message: 'QuestionPro was started in a garage in Seattle',
  },
  {
    type: TipType.TODAY_I_LEARNED,
    message:
      'The very first QuestionPro customer was a Professor @ Bighamton, NY',
  },
];

export const DcRelativeApp = () => {
  const params = useParams<{ dc: string }>();
  setGlobals(params.dc);

  return (
    <>
      <div className={styles.navbarContainer}>
        <Header />
      </div>
      <div className={styles.mainContainer}>
        <DashboardScreen />
      </div>
      <LpFooter
        text={{
          locations,
          tips,
          with: 'With',
          from: 'from',
        }}
      />
    </>
  );
};
