import { LikeSvg } from '@livepolls/ui-components/src/components/svgImages/Like.svg';
import styles from './LiveFeedComment.module.css';
import { BookmarkSvg } from '@livepolls/ui-components/src/components/svgImages/Bookmark.svg';
import { LiveFeedPinUnpin } from '@livepolls/ui-components/src/enums/livefeed-pin-unpin.enum';
import { LiveFeedQuestionResponse } from '@livepolls/ui-components/src/interfaces/livefeed-question-response';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { UseMutationResult } from 'react-query';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
  liveFeedResponse: LiveFeedQuestionResponse;
  commentPinUnpin?: () => UseMutationResult<
    void,
    unknown,
    { commentId: number; pinUnpin: LiveFeedPinUnpin },
    unknown
  >;
}

export const LiveFeedComment = ({
  liveFeedResponse,
  commentPinUnpin,
}: Props) => {
  const { id, isPinned, respondentName, text, upvoteCount } = liveFeedResponse;
  const [mutation] = useState<
    | UseMutationResult<
        void,
        unknown,
        { commentId: number; pinUnpin: LiveFeedPinUnpin },
        unknown
      >
    | undefined
  >((commentPinUnpin && commentPinUnpin()) || undefined);

  const handleCommentClick = () => {
    if (!mutation) {
      return;
    }

    mutation.mutate({
      commentId: id,
      pinUnpin:
        isPinned === LiveFeedPinUnpin.PIN
          ? LiveFeedPinUnpin.UNPIN
          : LiveFeedPinUnpin.PIN,
    });
  };

  const bookmarkIconClass =
    liveFeedResponse.isPinned === LiveFeedPinUnpin.PIN
      ? styles.bookmarkIconPinned
      : styles.bookmarkIconUnPinned;

  const showBookMarkIcon =
    (mutation && !mutation.isLoading) ||
    liveFeedResponse.isPinned === LiveFeedPinUnpin.PIN;

  const showLoader = mutation && mutation.isLoading;
  return (
    <div
      className={`${styles.container} ${mutation ? styles.containerHover : ''}`}
      onClick={handleCommentClick}
    >
      <div className={styles.bookmarkContainer}>
        <>
          {showBookMarkIcon && (
            <span className={bookmarkIconClass} data-testid="livefeed-bookmark">
              {BookmarkSvg()}
            </span>
          )}

          {showLoader && (
            <span className={styles.bookmarkIconLoading}>
              <FontAwesomeIcon icon={faSpinner} size="lg" color="#ccc" />
            </span>
          )}

          {!showBookMarkIcon && !showLoader && (
            <div className={styles.emptyDiv}></div>
          )}
        </>
      </div>

      <div className={styles.textContainer}>
        <span className={styles.comment} data-testid="livefeed-response-text">
          {text}
        </span>
        <span data-testid="livefeed-respondent-name">{respondentName}</span>
      </div>
      {upvoteCount > 0 && (
        <div className={styles.likesContainer}>
          <span className={styles.likeIcon}>
            <LikeSvg width="12px" height="12px" />
          </span>
          <span
            className={styles.likeCount}
            data-testid="livefeed-up-vote-count"
          >
            {getFormattedLikeCount(upvoteCount)}
          </span>
        </div>
      )}
    </div>
  );
};

// format number into letters
const getFormattedLikeCount = (upVoteCount: number): string => {
  const formatAfter = 1000;
  const roundUpto = 2; // decimal points

  if (upVoteCount < formatAfter) {
    return `${upVoteCount}`;
  }

  return `${(upVoteCount / formatAfter).toFixed(roundUpto)}K`;
};
