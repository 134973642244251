import { useEffect, useState } from 'react';
import { SwipeQuestionCard } from 'src/interfaces/swipe-question-card.interface';
import { SwipeQuestionOption } from 'src/interfaces/swipe-question-option.interface';
import {
  SwipeQuestionFinishData,
  SwipeQuestionResult,
} from 'src/interfaces/swipe-question-result.interface';
import styles from './LpSwipeQuestionFinishedScreen.module.css';
import ribbonSvg from '../../images/blue-ribbon.svg';
import greyRibbonSvg from '../../images/grey-ribbon.svg';
import whiteRibbonSvg from '../../images/white-ribbon.svg';
import clockSvg from '../../images/clock.svg';
import whiteClockSvg from '../../images/white-clock.svg';
import ascendingSvg from '../../images/ascending.svg';
import descendingSvg from '../../images/descending.svg';
import swipeLeftSvg from '../../images/swipe-left.svg';
import swipeRightSvg from '../../images/swipe-right.svg';
import checkmarkSvg from '../../images/checkmark.svg';
import { SwipeQuestionCardSide } from '../../interfaces/swipe-question-card-side.interface';
import { LpNewToolTip } from '../new-tooltip/LpNewToolTip';
import { ToolTipMarkerPositionEnum } from '../../enums/tooltip-marker-position.enum';
import { LpSpinner } from '../spinner/LpSpinner';

interface Props {
  isQuiz: boolean;
  swipeQuestion: {
    swipeOptions: SwipeQuestionOption;
    cards: SwipeQuestionCard[];
  };
  text: {
    noDataAvailable: string;
    initializing: string;
  };
  questionResult: SwipeQuestionResult | undefined;
  customCardRowClass?: string;
  customHeaderRowClass?: string;
  customContainerClass?: string;
}

interface SwipeQuestionFinishDataWithRank extends SwipeQuestionFinishData {
  rank: number;
}
enum SortOrder {
  DESC,
  ASC,
}
enum ChartView {
  RIGHT_SWIPES_RESPONSE_RATE,
  CHRONOLOGICAL,
}
export const LpSwipeQuestionFinishedScreen = ({
  isQuiz,
  swipeQuestion,
  questionResult,
  text,
  customCardRowClass,
  customHeaderRowClass,
  customContainerClass,
}: Props) => {
  const [responses, setResponses] = useState<SwipeQuestionFinishDataWithRank[]>(
    [],
  );

  const [updatedResponses, setUpdatedResponses] = useState<
    SwipeQuestionFinishDataWithRank[]
  >([]);

  const [currentView, setCurrentView] = useState<ChartView>(
    ChartView.RIGHT_SWIPES_RESPONSE_RATE,
  );

  const [order, setOrder] = useState<SortOrder>(SortOrder.DESC);
  const leftSideColor = '#FF7681';
  const leftSideText = swipeQuestion.swipeOptions.left.text;
  const rightSideText = swipeQuestion.swipeOptions.right.text;
  const rightSideColor = '#3FCA7F';
  const cards = swipeQuestion.cards;
  const lastRank = cards.length;

  useEffect(() => {
    const prepareSwipeQuestionResult = (
      swipeQuestionResponses: SwipeQuestionFinishData[] | undefined,
    ) => {
      if (!swipeQuestionResponses) {
        return [];
      }
      const rankedResponses = swipeQuestionResponses
        .map(item => ({
          ...item,
          percentage: getPercentage(
            Number(item.rightCount),
            Number(item.leftCount),
          ),
        }))
        .sort((a, b) => Number(b.percentage) - Number(a.percentage))
        .map((item, index) => ({
          ...item,
          rank: index + 1,
        }));

      const cardIdsWithResponses = new Set(
        swipeQuestionResponses.map(r => r.cardId),
      );
      const cardsWithoutResponses = cards.filter(
        card => !cardIdsWithResponses.has(card.id),
      );

      cardsWithoutResponses.forEach((card, index) => {
        rankedResponses.push({
          cardId: card.id,
          rightCount: 0,
          leftCount: 0,
          percentage: 0,
          rank: rankedResponses.length + index + 1,
        });
      });

      return rankedResponses;
    };

    const responses = prepareSwipeQuestionResult(
      questionResult?.swipeQuestionResponses,
    );

    setResponses(responses);
    setUpdatedResponses(responses);
  }, [cards, questionResult]);

  useEffect(() => {
    const prepareCardView = (responses: SwipeQuestionFinishDataWithRank[]) => {
      return cards.map(card => responses.find(res => res.cardId === card.id)!);
    };

    if (currentView === ChartView.CHRONOLOGICAL) {
      const response = prepareCardView(responses);
      if (order === SortOrder.ASC) {
        setUpdatedResponses([...response].reverse());
      } else {
        setUpdatedResponses(response);
      }
    } else {
      if (order === SortOrder.ASC) {
        setUpdatedResponses([...responses].reverse());
      } else {
        setUpdatedResponses(responses);
      }
    }
  }, [cards, currentView, responses, order]);

  if (!questionResult || updatedResponses.length === 0) {
    return <LpSpinner />;
  }

  const prepareDataForBar = (cardId: string) => {
    const responseForCard = updatedResponses.find(
      response => response.cardId === cardId,
    );
    const rightCount = Number(responseForCard?.rightCount) || 0;
    const leftCount = Number(responseForCard?.leftCount) || 0;
    const rightSidePercentage = getPercentage(rightCount, leftCount);
    const leftSidePercentage = getPercentage(leftCount, rightCount);
    return {
      left: leftSidePercentage,
      right: rightSidePercentage,
      rightCount,
      leftCount,
      rank: Number(responseForCard?.rank || 0),
    };
  };

  const getStyleForRankWithoutImage = (rank: number, lastRank: number) => {
    if (rank === 1) {
      return `${styles.firstRankForEmptyCard}`;
    } else if (rank === lastRank) {
      return `${styles.lastRankForEmptyCard}`;
    } else {
      return `${styles.cardRankForEmptyCard}`;
    }
  };

  const getStyleForRankWithImage = (rank: number, lastRank: number) => {
    if (rank === 1) {
      return `${styles.firstRank}`;
    } else if (rank === lastRank) {
      return `${styles.lastRank}`;
    } else {
      return `${styles.cardRank}`;
    }
  };

  const getStyleForCardWithImage = (rank: number, lastRank: number) => {
    if (rank === 1) {
      return `${styles.cardImagePic} ${styles.cardBorder}`;
    } else if (rank === lastRank) {
      return `${styles.cardImagePic} ${styles.lastCardBorder}`;
    } else {
      return `${styles.cardImagePic}`;
    }
  };
  const getStyleForCardWithoutImage = (rank: number, lastRank: number) => {
    if (rank === 1) {
      return `${styles.emptyCardImage} ${styles.cardBorderForEmptyCard}`;
    } else if (rank === lastRank) {
      return `${styles.emptyCardImage} ${styles.lastCardBorderForEmptyCard}`;
    } else {
      return `${styles.emptyCardImage}`;
    }
  };

  const displayRank = (
    imageUrl: string | undefined,
    rank: number,
    lastRank: number,
  ) => {
    return (
      <>
        {imageUrl ? (
          <div className={getStyleForRankWithImage(rank, lastRank)}>
            # <span>{rank}</span>
          </div>
        ) : (
          <div className={getStyleForRankWithoutImage(rank, lastRank)}>
            # <span>{rank}</span>
          </div>
        )}
      </>
    );
  };

  const displayCardImage = (
    imageUrl: string | undefined,
    rank: number,
    lastRank: number,
  ) => {
    return (
      <>
        {imageUrl ? (
          <>
            <img
              src={imageUrl}
              height={80}
              width={64}
              className={getStyleForCardWithImage(rank, lastRank)}
              alt="card pic"
            />
          </>
        ) : (
          <div className={getStyleForCardWithoutImage(rank, lastRank)}></div>
        )}
      </>
    );
  };

  const displayBars = (
    left: number,
    right: number,
    cardCorrectSide?: SwipeQuestionCardSide,
  ) => {
    return (
      <div className={styles.bar}>
        <div
          style={{ width: `${left}%`, background: leftSideColor }}
          className={
            left === 100 ? styles.borderRadius : styles.leftSideBorderRadius
          }
        >
          {isQuiz && cardCorrectSide === SwipeQuestionCardSide.LEFT && (
            <img
              src={checkmarkSvg}
              alt="correct card"
              className={styles.leftCheckMark}
            />
          )}
        </div>
        <div
          style={{ width: `${right}%`, background: rightSideColor }}
          className={
            right === 100 ? styles.borderRadius : styles.rightSideBorderRadius
          }
        >
          {isQuiz && cardCorrectSide === SwipeQuestionCardSide.RIGHT && (
            <img
              src={checkmarkSvg}
              alt="correct card"
              className={styles.rightCheckMark}
            />
          )}
        </div>
      </div>
    );
  };

  const getCardRow = (cardId: string) => {
    const card = swipeQuestion.cards.find(card => card.id === cardId);
    const { left, right, leftCount, rightCount, rank } = prepareDataForBar(
      card!.id,
    );

    const imageUrl = card?.image?.url;
    const cardCorrectSide = card?.correctSide;
    return (
      <div className={customCardRowClass ? customCardRowClass : styles.cardRow}>
        <div className={styles.leftSide}>
          {rank === 1 && (
            <img src={ribbonSvg} className={styles.ribbonImg} alt="rank" />
          )}
          <div className={styles.cardImage}>
            {displayRank(imageUrl, rank, lastRank)}

            {displayCardImage(imageUrl, rank, lastRank)}
          </div>
          <div
            className={rank === 1 ? styles.boldCardText : styles.cardText}
            data-testid="card title"
          >
            {card?.title || 'Untitled'}
          </div>
        </div>

        <div className={styles.rightSide}>
          <div className={styles.leftAndRightTextRow}>
            <div style={{ color: leftSideColor }}>{leftCount}</div>
            <div style={{ color: rightSideColor }}>{rightCount}</div>
          </div>
          {displayBars(left, right, cardCorrectSide)}
        </div>
      </div>
    );
  };

  const changeOrder = (sortOrder: SortOrder) => {
    setOrder(sortOrder);
  };

  const changeView = (view: ChartView) => {
    if (view === currentView) {
      return;
    }
    setCurrentView(view);
  };

  const topRow = () => {
    return (
      <div className={styles.header}>
        <div
          className={
            customHeaderRowClass ? customHeaderRowClass : styles.headerRow
          }
        >
          <div className={styles.leftSideForHeader}>
            <div className={styles.toggle}>
              <div
                className={
                  currentView === ChartView.RIGHT_SWIPES_RESPONSE_RATE
                    ? styles.activeViewForResponse
                    : styles.activeViewForCard
                }
              >
                {currentView === ChartView.RIGHT_SWIPES_RESPONSE_RATE ? (
                  <img src={whiteRibbonSvg} alt="active toggle responses" />
                ) : (
                  <img src={whiteClockSvg} alt="active toggle cards" />
                )}
              </div>
              <img
                height={16}
                width={16}
                src={greyRibbonSvg}
                onClick={() => changeView(ChartView.RIGHT_SWIPES_RESPONSE_RATE)}
                alt="disable toggle responses"
                className={styles.icon}
              />
              <img
                height={16}
                width={16}
                src={clockSvg}
                onClick={() => changeView(ChartView.CHRONOLOGICAL)}
                alt="disable toggle cards"
                className={styles.icon}
              />
            </div>
            {order === SortOrder.DESC ? (
              <img
                src={descendingSvg}
                onClick={() => changeOrder(SortOrder.ASC)}
                alt="desc order"
                className={styles.icon}
              />
            ) : (
              <img
                src={ascendingSvg}
                onClick={() => changeOrder(SortOrder.DESC)}
                alt="asc order"
                className={styles.icon}
              />
            )}
          </div>
          <div className={styles.rightSideForHeader}>
            <div className={styles.swipeOptionText}>
              <LpNewToolTip
                textOnHover={leftSideText}
                showToolTip={true}
                toolTipMarkerPosition={ToolTipMarkerPositionEnum.LEFT}
                toolTipTextCustomClass={styles.customToolTipForLeftClass}
              >
                <img
                  src={swipeLeftSvg}
                  className={styles.leftSideImage}
                  alt="swipe left"
                />
                <span
                  className={`${styles.leftSideText} ${
                    isQuiz ? styles.topRowMaxContent : ''
                  }`}
                >
                  {' '}
                  {leftSideText}
                </span>
              </LpNewToolTip>
            </div>
            <div className={styles.swipeOptionText}>
              <LpNewToolTip
                textOnHover={rightSideText}
                showToolTip={true}
                toolTipMarkerPosition={ToolTipMarkerPositionEnum.RIGHT}
                toolTipTextCustomClass={styles.customToolTipClass}
              >
                <span
                  className={`${styles.rightSideText} ${
                    isQuiz ? styles.topRowMaxContent : ''
                  } `}
                >
                  {' '}
                  {rightSideText}
                </span>
                <img
                  src={swipeRightSvg}
                  className={styles.rightSideImage}
                  alt="swipe right"
                />
              </LpNewToolTip>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`${styles.container} ${customContainerClass || ''}`}
      data-testid="swipe question finished"
    >
      <>{topRow()}</>
      <div className={styles.innerContainer}>
        {updatedResponses.map(response => {
          return <div key={response.cardId}>{getCardRow(response.cardId)}</div>;
        })}
      </div>
    </div>
  );
};

const getPercentage = (value: number, remainingValue: number) => {
  const total = value + remainingValue;
  if (value === 0) {
    return 0;
  }
  const percentage = (value * 100) / total;
  return percentage;
};
