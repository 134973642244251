import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { getGlobals } from 'src/globals/globals';

const callApi = async (
  endpoint: string,
  config?: AxiosRequestConfig,
): Promise<AxiosResponse> => {
  const API_URL = getGlobals()!.API_URL;
  try {
    return await axios({
      url: `${API_URL}${endpoint}`,
      withCredentials: true,
      ...config,
    });
  } catch (error: any) {
    if (error.response) {
      console.error(error.response.data);
      console.error(error.response.status);
      throw error.response.data;
    } else {
      console.error('Error', error.message);
      throw error.message;
    }
  }
};

export const getApi = async (endpoint: string, config?: AxiosRequestConfig) => {
  const response = await callApi(endpoint, { method: 'GET', ...config });
  return response.data;
};
