export const RanksSvg = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.28194 8H8.71806C9.16382 8 9.32546 8.04641 9.48842 8.13357C9.65139 8.22072 9.77928 8.34861 9.86643 8.51158C9.95359 8.67454 10 8.83618 10 9.28194V14.7181C10 15.1638 9.95359 15.3255 9.86643 15.4884C9.77928 15.6514 9.65139 15.7793 9.48842 15.8664C9.32546 15.9536 9.16382 16 8.71806 16H7.28194C6.83618 16 6.67454 15.9536 6.51158 15.8664C6.34861 15.7793 6.22072 15.6514 6.13357 15.4884C6.04641 15.3255 6 15.1638 6 14.7181V9.28194C6 8.83618 6.04641 8.67454 6.13357 8.51158C6.22072 8.34861 6.34861 8.22072 6.51158 8.13357C6.67454 8.04641 6.83618 8 7.28194 8ZM1.28194 12H2.71806C3.16382 12 3.32546 12.0464 3.48842 12.1336C3.65139 12.2207 3.77928 12.3486 3.86643 12.5116C3.95359 12.6745 4 12.8362 4 13.2819V14.7181C4 15.1638 3.95359 15.3255 3.86643 15.4884C3.77928 15.6514 3.65139 15.7793 3.48842 15.8664C3.32546 15.9536 3.16382 16 2.71806 16H1.28194C0.836183 16 0.67454 15.9536 0.511577 15.8664C0.348614 15.7793 0.22072 15.6514 0.133566 15.4884C0.0464128 15.3255 0 15.1638 0 14.7181V13.2819C0 12.8362 0.0464128 12.6745 0.133566 12.5116C0.22072 12.3486 0.348614 12.2207 0.511577 12.1336C0.67454 12.0464 0.836183 12 1.28194 12ZM13.2819 10H14.7181C15.1638 10 15.3255 10.0464 15.4884 10.1336C15.6514 10.2207 15.7793 10.3486 15.8664 10.5116C15.9536 10.6745 16 10.8362 16 11.2819V14.7181C16 15.1638 15.9536 15.3255 15.8664 15.4884C15.7793 15.6514 15.6514 15.7793 15.4884 15.8664C15.3255 15.9536 15.1638 16 14.7181 16H13.2819C12.8362 16 12.6745 15.9536 12.5116 15.8664C12.3486 15.7793 12.2207 15.6514 12.1336 15.4884C12.0464 15.3255 12 15.1638 12 14.7181V11.2819C12 10.8362 12.0464 10.6745 12.1336 10.5116C12.2207 10.3486 12.3486 10.2207 12.5116 10.1336C12.6745 10.0464 12.8362 10 13.2819 10ZM8 6L5.64886 7.23607L6.09789 4.61803L4.19577 2.76393L6.82443 2.38197L8 0L9.17557 2.38197L11.8042 2.76393L9.90211 4.61803L10.3511 7.23607L8 6Z"
        fill="currentColor"
      />
    </svg>
  );
};
