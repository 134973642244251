import { LeaderboardRow } from 'src/interfaces/leaderboard-row.interface';
import { TeamLeaderboardRow } from '@livepolls/ui-components/src/interfaces/team-leaderboard-row';
import { faMedal } from '@fortawesome/free-solid-svg-icons';

export const transformLeaderboardToPager = (
  leaderboardRows: LeaderboardRow[],
  isTeamsEnabled: boolean,
  text: {
    individualLeaderboard: string;
    leaderboard: string;
  },
) => {
  if (leaderboardRows.length === 0) {
    return undefined;
  }
  const countResults = leaderboardRows.map(row => {
    return {
      text: row.respondentName,
      count: row.score,
      percentage: row.answerAccuracy,
      rank: row.rank,
      color: row.teamColor,
    };
  });

  const leaderboardTitle = isTeamsEnabled
    ? text.individualLeaderboard
    : text.leaderboard;
  return [{ title: leaderboardTitle, results: countResults, icon: faMedal }];
};

export const transformTeamLeaderboardToPager = (
  teamLeaderboardRows: TeamLeaderboardRow[],
) => {
  if (teamLeaderboardRows.length === 0) {
    return undefined;
  }
  const countResults = teamLeaderboardRows.map((row, index) => {
    return {
      text: row.teamName,
      count: row.teamScore,
      percentage: row.teamAccuracy,
      rank: index + 1,
      color: row.teamColor,
    };
  });

  return [{ title: 'Team leaderboard', results: countResults, icon: faMedal }];
};
