import { useEffect, useState } from 'react';
import { LpLeaderboard } from '@livepolls/ui-components/src/components/leaderboard/LpLeaderboard';
import { LpPanelContainer } from '@livepolls/ui-components/src/components/panel/LpPanelContainer';
import { LpPanelHeading } from '@livepolls/ui-components/src/components/panel/LpPanelHeading';
import { DashboardPanelType } from '@livepolls/ui-components/src/enums/dashboard-panel-type.enum';
import { LpSpinner } from '@livepolls/ui-components/src/components/spinner/LpSpinner';
import styles from './DashboardReports.module.css';
import { LpResponseCountAndTime } from '@livepolls/ui-components/src/components/response-count-and-time/LpResponseCountAndTime';
import { LivePollQuestionReports } from './LivePollQuestionReports';
import { LpProfileQuestionReports } from '@livepolls/ui-components/src/components/livepoll-question-reports/components/LpProfileQuestionReports';
import {
  transformLeaderboardToPager,
  transformTeamLeaderboardToPager,
} from '@livepolls/ui-components/src/utils/generate-leaderboard-row';
import { ILivePollSession } from '@livepolls/ui-components/src/interfaces/livepoll-session.interface';
import { ILivePollSessionReport } from '@livepolls/ui-components/src/interfaces/livepoll-session-report.interface';
import { LivePollType } from '@livepolls/ui-components/src/enums/livepoll-type.enum';
import { getApi } from 'src/utils/api.util';
import { DashboardReportsForMobile } from './DashboardReportsForMobile';

interface Props {
  dashboardReportUid: string;
}

export const DashboardReports = ({ dashboardReportUid }: Props) => {
  const [panelType, setPanelType] = useState<DashboardPanelType>(
    DashboardPanelType.LIVEPOLL_QUESTIONS,
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const [reportPayload, setReportPayload] = useState<{
    livePoll: { id: number; type: LivePollType };
    livePollSession: ILivePollSession;
    livePollSessionReport: ILivePollSessionReport;
  }>();

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const { livePoll, livePollSession, livePollSessionReport } =
          await getApi(
            `/take/sharable-report/livepoll-session/report/${dashboardReportUid}`,
          );
        setReportPayload({
          livePoll,
          livePollSession,
          livePollSessionReport,
        });
      } catch (err: any) {
        setError(err);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [dashboardReportUid]);

  if (isLoading) {
    return (
      <div className={styles.loaderContainer}>
        <LpSpinner message="Loading Session Report..." />
      </div>
    );
  }

  if (error) {
    return <div>Oops an error occurred ({error?.message}).</div>;
  }

  if (!reportPayload?.livePoll) {
    return <div>Oops an error occurred (LivePoll not found).</div>;
  }

  if (!reportPayload.livePollSession) {
    return <div>Oops an error occurred (LivePollSession not found).</div>;
  }

  if (!reportPayload.livePollSessionReport) {
    return <div>Oops an error occurred (LivePollSessionReport not found).</div>;
  }

  const { livePoll, livePollSession, livePollSessionReport } = reportPayload;
  const isQuiz = livePoll.type === LivePollType.QUIZ;
  const {
    responseCount,
    questionReports,
    leaderboardRows,
    profileQuestionReports,
    teamLeaderboardRows,
  } = livePollSessionReport;

  const displayRightWidget = isQuiz;
  const leftDivStyle: React.CSSProperties = { width: isQuiz ? '75%' : '100%' };
  const handelPanelChange = (panelType: DashboardPanelType) => {
    setPanelType(panelType);
  };
  const isProfileQuestionsActive =
    panelType === DashboardPanelType.PROFILE_QUESTIONS;
  const isLivePollQuestionsActive =
    panelType === DashboardPanelType.LIVEPOLL_QUESTIONS;

  const showDifficultyLevels =
    !!livePollSession.setting?.displayDifficultyLevels;
  const enableQuestionDifficulty =
    !!livePollSession.setting?.enableQuestionDifficulty;
  const isTeamsEnabled = !!livePollSession.setting?.teamSetting?.enabled;

  const isMobileView = window.innerWidth <= 1000;
  if (isMobileView) {
    return (
      <DashboardReportsForMobile
        livePollSession={livePollSession}
        livePoll={livePoll}
        livePollSessionReport={livePollSessionReport}
      />
    );
  }

  return (
    <>
      <div className={styles.left} style={leftDivStyle}>
        <div className={styles.leftWrapper}>
          <div className={styles.questionsContainer}>
            <LpResponseCountAndTime
              livePollSession={livePollSession}
              responseCount={responseCount}
            />
            <LpPanelContainer customContainerClass={styles.panelContainer}>
              <LpPanelHeading
                onPanelHeadClick={() =>
                  handelPanelChange(DashboardPanelType.LIVEPOLL_QUESTIONS)
                }
                isActive={isLivePollQuestionsActive}
              >
                <span className={styles.panelText}>Questions</span>
              </LpPanelHeading>
              {livePollSession.setting?.enableProfileQuestions && (
                <LpPanelHeading
                  onPanelHeadClick={() =>
                    handelPanelChange(DashboardPanelType.PROFILE_QUESTIONS)
                  }
                  isActive={isProfileQuestionsActive}
                >
                  <span className={styles.panelText}>Profile Questions</span>
                </LpPanelHeading>
              )}
            </LpPanelContainer>
            {isLivePollQuestionsActive && (
              <LivePollQuestionReports
                livePollId={livePollSession.livePollId}
                livePollSessionId={livePollSession.id}
                isQuiz={isQuiz}
                responseCount={responseCount}
                questionReports={questionReports}
                profileQuestionReport={profileQuestionReports}
                showDifficultyLevels={showDifficultyLevels}
                enableQuestionDifficulty={enableQuestionDifficulty}
                teamLeaderboardRows={teamLeaderboardRows}
              />
            )}
            {isProfileQuestionsActive && (
              <LpProfileQuestionReports
                questionReports={profileQuestionReports!}
                responseCount={responseCount}
                text={{
                  noVotes: 'No votes',
                  outOf: 'out of',
                  votes: 'votes',
                  initializing: 'Initializing',
                  noDataAvailable: 'No Data Available',
                }}
              />
            )}
          </div>
        </div>
      </div>
      {displayRightWidget && (
        <div className={styles.dashboardReport}>
          {isTeamsEnabled && (
            <LpLeaderboard
              showSequenceNum={true}
              pagewiseResults={transformTeamLeaderboardToPager(
                teamLeaderboardRows || [],
              )}
              showPercentage={true}
              height="30%"
              showTeamColor={true}
              text={{ noDataAvailable: 'No Data Available' }}
            />
          )}
          <LpLeaderboard
            showSequenceNum={true}
            pagewiseResults={transformLeaderboardToPager(
              leaderboardRows,
              isTeamsEnabled,
              {
                individualLeaderboard: 'Individual Leaderboard',
                leaderboard: 'Leaderboard',
              },
            )}
            showPercentage={true}
            height={isTeamsEnabled ? '70%' : '100%'}
            showTeamColor={isTeamsEnabled}
            text={{ noDataAvailable: 'No Data Available' }}
          />
        </div>
      )}
    </>
  );
};
