import * as React from 'react';
import styles from './Header.module.css';
import { LpProductDropdown } from '@livepolls/ui-components/src/components/product-dropdown/LpProductDropdown';
import { getProductUrls } from 'src/utils/product-urls.utils';

export const Header = () => {
  const productUrls = getProductUrls();

  return (
    <div className={styles.header}>
      <div className={styles.topHeader}>
        <LpProductDropdown productUrls={productUrls} />
      </div>
    </div>
  );
};
