const MAX_SHADES = 10;
const PRIMARY_COLOR = ['#1b3380', '#1B3380'];
const SECONDARY_COLOR = '#8DC3F2';

export const getRGBValues = (color: string) => {
  var vals = color
    .substring(color.indexOf('(') + 1, color.length - 1)
    .split(',');
  return {
    red: vals[0],
    green: vals[1],
    blue: vals[2],
  };
};

export const hexToRGB = (hex: any) => {
  return `rgb(${hex.match(/\w\w/g).map((color: string) => +`0x${color}`)})`;
};

export const getLightColor = (color: string) => {
  if (PRIMARY_COLOR.indexOf(color) > -1) {
    return SECONDARY_COLOR;
  }
  const rgbColor = getRGBValues(hexToRGB(color));
  const red = Number(rgbColor.red) + 80;
  const green = Number(rgbColor.green) + 80;
  const blue = Number(rgbColor.blue) + 80;

  return `rgb(${red}, ${green}, ${blue})`;
};

export const generateShadesOfColor = (
  colorInHex: string,
  noOfShades: number,
) => {
  if (noOfShades > MAX_SHADES) {
    throw new Error(
      `maximum ${MAX_SHADES} shades of the color are allowed per question`,
    );
  }
  const color = getRGBValues(hexToRGB(colorInHex));
  var r = Number(color.red);
  var g = Number(color.green);
  var b = Number(color.blue);
  const shades = [];
  const rgbColor = `rgb(${r} , ${g} , ${b} )`;
  shades.push(rgbColor);

  for (var i = 1; i < noOfShades; i++) {
    r += 50;
    g += 50;
    b += 50;

    const rgbColor = `rgb(${r} , ${g} , ${b} )`;
    shades.push(rgbColor);
  }
  return shades;
};
