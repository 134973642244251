export const LiveFeedSvg = () => {
  const image = (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.66667 1.33333V0L8.4621 0.00236696C3.77013 0.111048 0 3.94858 0 8.66667H1.33333L1.3358 8.4744C1.43776 4.51318 4.68087 1.33333 8.66667 1.33333ZM8.66667 2.66667C5.35296 2.66667 2.66667 5.35296 2.66667 8.66667V12H8.66667L13.3333 16V12H16V2.66667H8.66667ZM8.66665 5.33337V4.00004L8.50621 4.00275C6.00317 4.08731 3.99998 6.14307 3.99998 8.66671H5.33331L5.33639 8.52211C5.4121 6.74825 6.87414 5.33337 8.66665 5.33337ZM9.33331 8.00004C9.33331 8.73642 8.73636 9.33337 7.99998 9.33337C7.2636 9.33337 6.66665 8.73642 6.66665 8.00004C6.66665 7.26366 7.2636 6.66671 7.99998 6.66671C8.73636 6.66671 9.33331 7.26366 9.33331 8.00004Z"
        fill="currentColor"
      />
    </svg>
  );
  return image;
};
