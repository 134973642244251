import { useEffect, useRef } from 'react';
import styles from './LpImagePopup.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

interface Props {
  image?: { url: string };
  closeImagePopup(): void;
}
export const LpImagePopup = ({ image, closeImagePopup }: Props) => {
  const slider = useRef<HTMLDivElement | null>(null);
  const bar = useRef<HTMLDivElement | null>(null);
  const img = useRef<HTMLImageElement | null>(null);

  const sliderInit = (e: any) => {
    e.preventDefault();
    document.addEventListener('mousemove', sliderDrag);
    document.addEventListener('mouseup', e => {
      document.removeEventListener('mousemove', sliderDrag);
    });
  };

  function sliderDrag(e: any) {
    //Variables aren't set as global to account for possible window resize
    let x0 = bar.current!.getBoundingClientRect().left;
    let width = bar.current!.offsetWidth;
    let dX = e.clientX - x0;
    let dPercent = dX / width;
    let sliderOff = slider.current!.offsetLeft;

    slider.current!.style.left = valBetween((dX / width) * 100, 0, 100) + '%';

    img.current!.style.transform =
      'translate3d(0%,0%,0) scale(' +
      (sliderOff / width < 0.5
        ? setZoomVal(0.2, dPercent)
        : setZoomVal(2, dPercent, false)) +
      ')';
  }

  useEffect(() => {
    slider.current?.addEventListener('mousedown', sliderInit);
    bar.current?.addEventListener('mousedown', sliderInit);
  });

  return (
    <div className={styles.imagePopup} data-testid="imagePopup">
      <div className={styles.imagePopupInner}>
        <div className={styles.imageRow}>
          <img
            id="questionImage"
            src={image?.url}
            alt="question pic"
            className={styles.questionImage}
            ref={img}
            title="question image"
          />
          <div
            className={styles.closeIcon}
            onClick={closeImagePopup}
            title="close popup"
          >
            <FontAwesomeIcon
              icon={faTimes}
              className={styles.closeIcon}
              data-testid="closeIcon"
            />
          </div>
        </div>

        <div className={styles.barWrap} title="zoom">
          <div className={styles.plusMinus}>-</div>
          <div className={styles.zoomBar} ref={bar}>
            <div className={styles.slider} ref={slider}></div>
          </div>
          <div className={styles.plusMinus}>+</div>
        </div>
      </div>
    </div>
  );
};

//Min-max function to set up slider boundaries
function valBetween(value: number, min: number, max: number) {
  return Math.min(max, Math.max(min, value));
}

//function to define zoom bottom half and top half ranges
let setZoomVal = function (value: number, percent: number, botVal = true) {
  return botVal
    ? valBetween((percent * (1 - value)) / 0.5, 0, 1 - value) + value
    : valBetween(percent * (value * 2 - 2), value - 1, value * 2 - 2) -
        (value - 2);
};
