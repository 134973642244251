import { IAnswerWithColor } from '../../../interfaces/answer-with-color.interface';
import { IMultipleChoiceQuestionReport } from '../../../interfaces/multiple-choice-question-report';
import { IFilterByProfileQuestionReport } from '../../../interfaces/filter-by-profile-question-report.interface';
import { LpFilterByProfileQuestionChart } from '../../filter-by-profile-question-chart/LpFilterByProfileQuestionChart';
import { LpMultipleChoiceQuestionLeaderboard } from '../../livepoll-question-reports/components/LpMultipleChoiceQuestionLeaderboard';
import styles from './LpFilterByProfileQuestionReport.module.css';
import { QuestionDifficultyLevel } from 'src/enums/question-difficulty-level.enum';
import { CSSProperties } from 'react';

interface Props {
  isQuiz: boolean;
  responseCount: number;
  questionReport: IMultipleChoiceQuestionReport;
  answersWithColor: IAnswerWithColor[];
  filterByProfileQuestionReport: IFilterByProfileQuestionReport;
  showDifficultyLevels?: boolean;
  difficultyLevel?: QuestionDifficultyLevel;
  difficultyLevelIndicatorStyle: CSSProperties;
}

export const LpFilterByProfileQuestionReport = ({
  isQuiz,
  responseCount,
  questionReport,
  answersWithColor,
  filterByProfileQuestionReport,
  showDifficultyLevels,
  difficultyLevel,
  difficultyLevelIndicatorStyle,
}: Props) => {
  const { answerStats } = questionReport;
  return (
    <div className={styles.chartRow}>
      <div>
        <LpFilterByProfileQuestionChart
          isQuiz={isQuiz}
          answerStats={answerStats}
          currentQuestionNumber={questionReport.currentQuestionNumber}
          totalQuestionCount={questionReport.totalQuestionCount}
          title={questionReport.title}
          filterByProfileQuestionReport={filterByProfileQuestionReport}
          answersWithColor={answersWithColor}
          image={questionReport.image}
          showDifficultyLevels={showDifficultyLevels}
          difficultyLevel={difficultyLevel}
          difficultyLevelIndicatorStyle={difficultyLevelIndicatorStyle}
        />
      </div>
      {isQuiz && (
        <div className={styles.questionLeaderboardColumn}>
          <LpMultipleChoiceQuestionLeaderboard
            questionReport={questionReport}
            responseCount={responseCount}
          />
        </div>
      )}
    </div>
  );
};
