import { IWordCloudQuestionReport } from '../../../interfaces/word-cloud-report.interface';
import { LpWordCloudImage } from '../../word-cloud/LpWordCloud';
import { formatWordCloudResults } from '../../../utils/format-word-cloud-results.util';
import { LpQuestionTitle } from '../../question-title/LpQuestionTitle';
import styles from './LpWordCloudReport.module.css';
import React from 'react';

const MAX_ANSWERS_TO_DISPLAY = 3;

interface Props {
  wordCloudQuestionReport: IWordCloudQuestionReport;
  text: {
    mentions: string;
    noDataAvailable: string;
  };
}

export const LpWordCloudReport = React.memo(
  ({ wordCloudQuestionReport, text }: Props) => {
    const results = formatWordCloudResults(
      wordCloudQuestionReport.wordCloudAnswers,
    );

    const topThreeAnswers = () => {
      const maxAnswersToDisplay =
        results.length >= MAX_ANSWERS_TO_DISPLAY
          ? MAX_ANSWERS_TO_DISPLAY
          : results.length;

      const answerText = maxAnswersToDisplay !== 1 ? 'Answers' : 'Answer';
      return (
        <>
          <div className={styles.heading} data-testid="topAnswerHeading">
            Top {maxAnswersToDisplay} {answerText}
          </div>
          <div>
            {results.slice(0, maxAnswersToDisplay).map(res => {
              return (
                <div key={res.name} className={styles.answerAndWeight}>
                  <div className={styles.answer} data-testid="topThreeAnswer">
                    {res.name}
                  </div>
                  <div className={styles.weight}>
                    {text.mentions}: {res.weight}
                  </div>
                </div>
              );
            })}
          </div>
        </>
      );
    };

    return (
      <div className={styles.container}>
        <LpQuestionTitle
          currentQuestionNumber={
            wordCloudQuestionReport.currentQuestionNumber || 0
          }
          title={wordCloudQuestionReport.title || ''}
        />
        <div className={styles.innerContainer}>
          {results.length === 0 ? (
            <div className={styles.noDataAvailable}>No responses</div>
          ) : (
            <>
              <LpWordCloudImage
                wordCloudQuestionResponses={results}
                text={{
                  mentions: text.mentions,
                }}
              />
              <div className={styles.topThreeAnswers}>{topThreeAnswers()}</div>
            </>
          )}
        </div>
      </div>
    );
  },
);
