import React, { CSSProperties, useCallback, useEffect, useRef } from 'react';
import Highcharts, { Chart } from 'highcharts';
import styles from './LpFilterByProfileQuestionChart.module.css';
import { LpQuestionTitle } from '../question-title/LpQuestionTitle';
import { IAnswerWithColor } from '../../interfaces/answer-with-color.interface';
import {
  IProfileAnswerReport,
  IFilterByProfileQuestionReport,
} from '../../interfaces/filter-by-profile-question-report.interface';
import { QuestionDifficultyLevel } from 'src/enums/question-difficulty-level.enum';

interface Props {
  isQuiz: boolean;
  title?: string;
  answerStats: {
    id: string;
    count: number;
    text?: string;
    isCorrect: boolean;
  }[];
  barColor?: string;
  highlightBarColor?: string;
  chartColor?: string;
  currentQuestionNumber?: number;
  totalQuestionCount?: number;
  filterByProfileQuestionReport: IFilterByProfileQuestionReport;
  answersWithColor: IAnswerWithColor[];
  image?: { url: string };
  showDifficultyLevels?: boolean;
  difficultyLevel: QuestionDifficultyLevel | undefined;
  difficultyLevelIndicatorStyle: CSSProperties;
}
interface Answer {
  isCorrect?: boolean;
  count: number;
  id: string;
  image?: { url: string };
}

const getTopVotedAnswer = (answers: Answer[]) => {
  let topVotedAnswer = answers[0];
  answers.forEach((answer: Answer) => {
    if (topVotedAnswer.count < answer.count) {
      topVotedAnswer = answer;
    }
  });
  return topVotedAnswer;
};

const makeHighLightedAnswers = (isQuiz: boolean, answerStats: Answer[]) => {
  const highLightedAnswers: Answer[] = [];
  if (!isQuiz) {
    const topVotedAnswer = getTopVotedAnswer(answerStats);

    highLightedAnswers.push(topVotedAnswer);
  } else {
    answerStats.forEach((answerStat: Answer) => {
      if (answerStat.isCorrect) {
        highLightedAnswers.push(answerStat);
      }
    });
  }
  return highLightedAnswers;
};

const getYAxisMaxCount = (maxAnswerCount: number) => {
  if (maxAnswerCount > 0 && maxAnswerCount < 10) {
    return (maxAnswerCount += 1);
  } else if (maxAnswerCount >= 10 && maxAnswerCount < 50) {
    return (maxAnswerCount += 5);
  } else {
    return (maxAnswerCount += 10);
  }
};

const generateSeries = (
  chart: Chart,
  filterByProfileQuestionReport: IFilterByProfileQuestionReport,
  answersWithColor: IAnswerWithColor[],
) => {
  filterByProfileQuestionReport.profileAnswersReport.forEach(
    (profileAnswerReport: IProfileAnswerReport) => {
      const answerWithColor = answersWithColor.find(
        answerWithColor => answerWithColor.id === profileAnswerReport.id,
      );
      chart.addSeries({
        name: profileAnswerReport.name,
        data: [...profileAnswerReport.livePollAnswersCount],
        type: 'bar',
        color: answerWithColor?.color,
        pointWidth: 32,
      });
    },
  );
};

export const LpFilterByProfileQuestionChart = ({
  isQuiz,
  barColor = '#C3E2FF',
  highlightBarColor = '#1f357b',
  chartColor = '#545E6B',
  title,
  answerStats,
  currentQuestionNumber,
  filterByProfileQuestionReport,
  answersWithColor,
  image,
  showDifficultyLevels,
  difficultyLevel,
  difficultyLevelIndicatorStyle,
}: Props) => {
  const ref = React.useRef<HTMLDivElement | null>(null);
  const highChartsRef = useRef<any>(null);

  const scrollHandler = useCallback(() => {
    if (highChartsRef.current) {
      (highChartsRef.current as any).tooltip.hide();
    }
  }, []);

  useEffect(() => {
    document.body.addEventListener('scroll', scrollHandler, { capture: true });
    return () => {
      document.body.removeEventListener('scroll', scrollHandler, {
        capture: true,
      });
    };
  }, [scrollHandler]);

  React.useEffect(() => {
    if (!ref.current) {
      return;
    }
    const highLightedAnswers = makeHighLightedAnswers(isQuiz, answerStats);
    const maxAnswerCount = answerStats.reduce(
      (answer1, answer2) =>
        (answer1 = answer1 > answer2.count ? answer1 : answer2.count),
      0,
    );
    const yAxisMaxCount = getYAxisMaxCount(maxAnswerCount);

    highChartsRef.current = Highcharts.chart(ref.current, {
      chart: {
        height: calculateHeight(answerStats.length),
        type: 'bar',
        backgroundColor: 'rgba(0,0,0,0)',
        marginLeft: 350,
        events: {
          load: function (this) {
            generateSeries(
              this,
              filterByProfileQuestionReport,
              answersWithColor,
            );
          },
        },
      },
      title: {
        text: '',
      },
      xAxis: {
        categories: answerStats.map(answerStat => answerStat.text || ''),
        labels: {
          useHTML: true,
          style: {
            color: chartColor,
            fontSize: '16',
            fontFamily: 'Fira Sans, sans-serif',
          },
          formatter: function () {
            const sequenceNumber = '';

            let result: Answer;
            answerStats.forEach((answer, index) => {
              if (index === this.pos) {
                result = answer;
              }
            });

            const highLightedAnswer = highLightedAnswers.find(
              item => item.id === result.id,
            );
            const boldRow = highLightedAnswer ? true : false;
            const text = (sequenceNumber + this.value) as string;

            const image = getImage(result!);
            if (boldRow) {
              const checkMarkImage = isQuiz ? getCheckMarkImage() : '';
              const paddingLeft = isQuiz
                ? 'padding-left: 0'
                : 'padding-left: 45px';
              return `<div class=${styles.boldrow}>
              ${checkMarkImage}<div style="${paddingLeft}" class=${styles.row}>${text}</div>
              <div class=${styles.toolTipText}>${text}</div>
              ${image}
              </div>`;
            } else {
              const paddingLeft = isQuiz
                ? 'padding-left: 30px'
                : 'padding-left: 45px';
              return `<div class=${styles.inCorrectAnswerRow}><div style="${paddingLeft}" class=${styles.inCorrectAnswer}>
                       ${text} 
                      </div>
                      <div class=${styles.toolTipText}>${text}</div>
                      ${image}
                      </div>`;
            }
          },
          align: 'left',
          x: -350,
        },
        lineWidth: 1.5,
        lineColor: chartColor,
      },
      yAxis: {
        max: yAxisMaxCount,
        min: 0,
        title: {
          text: '',
        },
        gridLineColor: 'transparent',

        lineWidth: 1.5,
        lineColor: chartColor,
        labels: {
          style: {
            color: chartColor,
            visibility: 'hidden',
          },
        },
        reversedStacks: false,
        stackLabels: {
          enabled: true,
          style: {
            color: '#aaa',
            fontSize: '12px',
            fontWeight: '700',
          },
          formatter: function () {
            return `${this.total}`;
          },
        },
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          animation: false,
          stacking: 'normal',
        },
      },
      tooltip: {
        hideDelay: 0,
        backgroundColor: '#f5f5f5',
        outside: true,
        borderColor: 'transparent',
        formatter: function () {
          return `${this.point.series.name} ${this.y}`;
        },
      },
      credits: {
        enabled: false,
      },
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 700,
              minWidth: 651,
            },
            chartOptions: {
              chart: {
                marginLeft: 280,
              },
              xAxis: {
                labels: {
                  x: -280,
                },
              },
            },
          },
          {
            condition: {
              maxWidth: 650,
              minWidth: 621,
            },
            chartOptions: {
              chart: {
                marginLeft: 260,
              },
              xAxis: {
                labels: {
                  x: -260,
                },
              },
            },
          },
          {
            condition: {
              maxWidth: 620,
              minWidth: 521,
            },
            chartOptions: {
              chart: {
                marginLeft: 240,
              },
              xAxis: {
                labels: {
                  x: -240,
                },
              },
            },
          },
          {
            condition: {
              maxWidth: 520,
              minWidth: 371,
            },
            chartOptions: {
              chart: {
                marginLeft: 200,
              },
              xAxis: {
                labels: {
                  x: -200,
                },
              },
            },
          },
          {
            condition: {
              maxWidth: 370,
            },
            chartOptions: {
              chart: {
                marginLeft: 180,
              },
              xAxis: {
                labels: {
                  x: -180,
                },
              },
            },
          },
        ],
      },
    });
  }, [
    answerStats,
    barColor,
    chartColor,
    highlightBarColor,
    isQuiz,
    filterByProfileQuestionReport,
    answersWithColor,
  ]);

  const getCheckMarkImage = () => {
    const checkmark = `<div>
      <span class=${styles.checkmark}>        
        <div class=${styles.checkmark_stem}></div>
        <div class=${styles.checkmark_kick}></div>
      </span>
    </div>`;
    return checkmark;
  };

  const calculateHeight = (numberOfAnswers: number) => {
    if (numberOfAnswers > 0) {
      return numberOfAnswers * 70;
    }
    return 140;
  };

  const getImage = (answer: Answer) => {
    const imageSrc = answer!.image?.url;
    const showImage = imageSrc ? true : false;

    const image = `<div class=${styles.image}>      
            ${
              imageSrc !== undefined && showImage
                ? `<img src="${imageSrc}" class=${styles.answerImage} height="40px"
                         width="60px"/>`
                : ''
            }
            </div>`;
    return image;
  };

  return (
    <div className={styles.chartContainer}>
      <div
        className={styles.chartTitle}
        style={{ color: chartColor }}
        title="question title"
      >
        <LpQuestionTitle
          currentQuestionNumber={currentQuestionNumber || 0}
          title={title}
          image={image}
          showDifficultyLevels={showDifficultyLevels}
          difficultyLevel={difficultyLevel}
          difficultyLevelIndicatorStyle={difficultyLevelIndicatorStyle}
        />
      </div>
      <div
        ref={ref}
        data-testid="filter-by-profile-question-chart"
        className={styles.chartInnerContainer}
      />
    </div>
  );
};
