import React from 'react';
import styles from './LpLeaderboard.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IconDefinition,
  faCircle,
  faMedal,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import medalImage from '../../images/leaderboard-icon.svg';
import { RibbonSvg } from '../svgImages/Ribbon.svg';
interface State {
  prevPageIndex: number;
  currentPageIndex: number;
}

interface Result {
  text: string;
  count: number;
  percentage?: number;
  rank: number;
  color?: string;
}

interface PageResult {
  title: string;
  results: Result[];
  blueColor?: boolean;
  icon: IconDefinition;
}

interface Props {
  showSequenceNum?: boolean;
  pagewiseResults: PageResult[] | undefined;
  text: {
    noDataAvailable: string;
  };
  showPercentage?: boolean;
  height?: string;
  showTeamColor?: boolean;
}

const defaultLeaderboard = {
  title: 'Leaderboard',
  results: [],
  icon: faMedal,
};
export class LpLeaderboard extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      currentPageIndex: 0,
      prevPageIndex: -1,
    };
  }

  public render() {
    if (this.props.pagewiseResults === undefined) {
      return (
        <div className={styles.itemContainer}>
          {this.renderHeader(defaultLeaderboard)}
          <div className={styles.noDataAvailableMsg}>
            {this.props.text.noDataAvailable}
          </div>
        </div>
      );
    }

    this.props.pagewiseResults.map(res =>
      res.results.sort((res1: Result, res2: Result) => {
        return res1.rank - res2.rank;
      }),
    );

    const color = this.props.pagewiseResults[this.state.currentPageIndex]
      .blueColor
      ? styles.blueColor
      : '';
    return (
      <div
        className={styles.resultContainerWrapper}
        style={{ height: this.props.height }}
      >
        <div className={styles.positionWrapper}>
          <div className={styles.resultContainer + ' ' + color}>
            <div className={styles.slideshowContainer}>
              {this.renderPagewise()}
            </div>
            {this.renderFooter()}
          </div>
        </div>
      </div>
    );
  }

  private renderHeader(pr: PageResult) {
    return (
      <div className={styles.questionHeader}>
        <h2 className={styles.questionText}>
          {pr.icon === faMedal && (
            <img
              alt="medal"
              src={medalImage}
              width="24px"
              height="24px"
              style={{ paddingRight: '24px' }}
            />
          )}
          {pr.icon === faUsers && (
            <FontAwesomeIcon
              icon={pr.icon}
              size="lg"
              style={{ paddingRight: '10px' }}
            />
          )}
          {pr.title}
        </h2>
      </div>
    );
  }

  private renderPagewise() {
    const { pagewiseResults, showSequenceNum, showPercentage } = this.props;

    return pagewiseResults!.map((pr, index) => {
      return (
        <div key={index} className={styles.itemContainer}>
          {this.renderHeader(pr)}

          <div className={styles.answerRowsContainer}>
            <div className={styles.answerRows}>
              <table
                className={styles.leaderboardTable}
                data-testid="leaderboard-table"
              >
                <tbody>
                  {pr.results.map((result, index) => {
                    return (
                      <tr
                        key={'respondent' + index}
                        data-testid="leaderboard-row"
                      >
                        <td>
                          {result.rank === 1 && (
                            <span data-testid="ribbonImg">
                              <RibbonSvg />
                            </span>
                          )}
                        </td>
                        {this.props.showTeamColor && (
                          <td className={styles.teamColorContainer}>
                            <div
                              className={styles.teamColor}
                              style={{ backgroundColor: result.color }}
                            ></div>
                          </td>
                        )}
                        {showSequenceNum && (
                          <td
                            className={styles.sequenceNum}
                            aria-label="leaderboard sequence number"
                          >
                            {result.rank}
                          </td>
                        )}
                        <td
                          className={styles.name}
                          title={
                            result.text.length > 16 ? result.text : undefined
                          }
                          aria-label="leaderboard name"
                        >
                          {this.ellipsisIfLong(result.text)}
                        </td>
                        {showPercentage && result.percentage != null && (
                          <td
                            aria-label="leaderboard accuracy percentage"
                            className={styles.accuracy}
                          >
                            <div className={styles.percent}>
                              {Math.round(result.percentage)}%
                            </div>
                            <div
                              className={
                                styles.progress +
                                ' ' +
                                (pr.blueColor ? styles.blue : '')
                              }
                            >
                              <div
                                className={styles.bar}
                                style={{ width: `${result.percentage}%` }}
                              />
                            </div>
                          </td>
                        )}
                        <td
                          className={styles.count}
                          aria-label="leaderboard score count"
                        >
                          {result.count}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      );
    });
  }

  private ellipsisIfLong(text: string) {
    return text.length > 18 ? text.substr(0, 18) + '...' : text;
  }

  private renderFooter() {
    const { pagewiseResults } = this.props;
    if (pagewiseResults == null || pagewiseResults.length < 2) {
      return null;
    }

    const { currentPageIndex } = this.state;
    return (
      <div className={styles.resultAccordion}>
        {pagewiseResults.map((pr, i) => (
          <span
            key={i}
            style={currentPageIndex === i ? {} : { color: '#5f5852' }}
            onClick={
              currentPageIndex === i
                ? undefined
                : this.handleGoToNextQuestion.bind(this, i)
            }
          >
            <FontAwesomeIcon icon={faCircle} size="1x" />
          </span>
        ))}
      </div>
    );
  }

  private handleGoToNextQuestion = (i: number) => {
    const { currentPageIndex } = this.state;

    this.setState({
      prevPageIndex: currentPageIndex,
      currentPageIndex: i,
    });
  };
}
