import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './LpProductDropdown.module.css';
import { faAngleRight, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { useOnClickOutside } from '../../utils/hooks.util';

import researchSuite from '../../images/header/product-switcher/research-suite.svg';
import customerExperience from '../../images/header/product-switcher/customer-experience.svg';
import workforce from '../../images/header/product-switcher/workforce.svg';
import freePackage from '../../images/header/product-switcher/free-package.svg';
import surveys from '../../images/header/product-switcher/product/surveys.svg';
import surveysHoverImg from '../../images/header/product-switcher/product/hover/surveys.svg';
import communities from '../../images/header/product-switcher/product/communities.svg';
import communitiesHoverImg from '../../images/header/product-switcher/product/hover/communities.svg';
import audience from '../../images/header/product-switcher/product/audience.svg';
import audienceHoverImg from '../../images/header/product-switcher/product/hover/audience.svg';
import insightsHub from '../../images/header/product-switcher/product/insightsHub.svg';
import insightsHubHoverImg from '../../images/header/product-switcher/product/hover/insightsHub.svg';
import livepollsHoverImg from '../../images/header/product-switcher/product/hover/livepolls.svg';
import cx from '../../images/header/product-switcher/product/cx.svg';
import cxHoverImg from '../../images/header/product-switcher/product/hover/cx.svg';
import workforceIcon from '../../images/header/product-switcher/product/workforce.svg';
import workforceHoverImg from '../../images/header/product-switcher/product/hover/workforce.svg';
import reputation from '../../images/header/product-switcher/product/reputation.svg';
import reputationHoverImg from '../../images/header/product-switcher/product/hover/reputation.svg';
import outerloop from '../../images/header/product-switcher/product/outerloop.svg';
import outerloopHoverImg from '../../images/header/product-switcher/product/hover/outerloop.svg';
import qpLogo from '../../images/qp-logo.svg';
import { IProductUrls } from 'src/interfaces/product-urls.interface';

const hostUrl = window.location.host;
const devUrl = 'dev.livepoll.com';

const livePollsUrl =
  hostUrl === devUrl ? 'http://' + hostUrl : 'https://' + hostUrl;

function getProductCategories(productUrls: IProductUrls) {
  return [
    {
      title: 'Research Suite',
      description: 'Powerful research tools',
      icon: researchSuite,
      color: '#1b3480',
      products: [
        {
          name: 'Surveys',
          url: productUrls.surveys,
          icon: surveys,
          hoverIcon: surveysHoverImg,
        },
        {
          name: 'Communities',
          url: productUrls.communities,
          icon: communities,
          hoverIcon: communitiesHoverImg,
        },
        {
          name: 'Audience',
          url: productUrls.audience,
          icon: audience,
          hoverIcon: audienceHoverImg,
        },
        {
          name: 'InsightsHub',
          url: productUrls.insightsHub,
          icon: insightsHub,
          hoverIcon: insightsHubHoverImg,
        },
      ],
    },
    {
      title: 'Customer Experience',
      description: 'Customer experience tools',
      icon: customerExperience,
      color: '#41c56d',
      products: [
        {
          name: 'CX',
          url: productUrls.customerExperience,
          icon: cx,
          hoverIcon: cxHoverImg,
        },
        {
          name: 'Surveys',
          url: productUrls.surveys,
          icon: surveys,
          hoverIcon: surveysHoverImg,
        },
        {
          name: 'Reputation',
          url: productUrls.reputation,
          icon: reputation,
          hoverIcon: reputationHoverImg,
        },
        {
          name: 'Outerloop',
          url: productUrls.outerloop,
          icon: outerloop,
          hoverIcon: outerloopHoverImg,
        },
      ],
    },
    {
      title: 'Workforce',
      description: 'Workforce experience tools',
      icon: workforce,
      color: '#a554eb',
      products: [
        {
          name: 'Workforce',
          url: productUrls.workforce,
          icon: workforceIcon,
          hoverIcon: workforceHoverImg,
        },
        {
          name: 'Surveys',
          url: productUrls.surveys,
          icon: surveys,
          hoverIcon: surveysHoverImg,
        },
      ],
    },
    {
      title: 'Engagement Tools',
      description: 'Engage your audience. Free of charge.',
      icon: freePackage,
      color: '#ed22a1',
      products: [
        {
          name: 'LivePolls',
          url: livePollsUrl,
          icon: livepollsHoverImg,
          hoverIcon: livepollsHoverImg,
        },
      ],
    },
  ];
}

interface Props {
  productUrls: IProductUrls;
}

export const LpProductDropdown = (props: Props) => {
  const [isDropdownOpen, setDropdownOpen] = React.useState<boolean>(false);
  const dropdownRef = React.useRef<HTMLUListElement | null>(null);
  const dropDownBtnRef = React.useRef<HTMLButtonElement | null>(null);
  // Close the dropdown on outside click
  useOnClickOutside(dropdownRef, dropDownBtnRef, () => {
    setDropdownOpen(false);
  });

  const handleToggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const productCategories = getProductCategories(props.productUrls);

  return (
    <div className={styles.qpProductDropdown}>
      <button
        type="button"
        className={styles.dropdownToggle}
        onClick={handleToggleDropdown}
        ref={dropDownBtnRef}
      >
        <span className={styles.text}>
          <img
            src={qpLogo}
            className={styles.qpIcon}
            alt="LivePolls"
            height="20px"
            width="20px"
          />{' '}
          LivePolls
        </span>
        <FontAwesomeIcon
          icon={faCaretDown}
          className={`${styles.dropdownIcon} ${
            isDropdownOpen ? styles.dropdownIconOpen : ''
          }`}
        />
      </button>

      <ul
        className={`${styles.dropdownMenu} ${
          isDropdownOpen ? styles.dropdownMenuOpen : ''
        }`}
        ref={dropdownRef}
      >
        {productCategories.map((category, index) => {
          return (
            <li key={index} className={styles.surveys}>
              <div
                className={styles.borderTop}
                style={{ backgroundColor: category.color }}
              ></div>

              <div className={styles.productLink}>
                <div className={styles.whiteBack}></div>
                <span className={styles.productIcon}>
                  <img src={category.icon} alt={category.title} />
                </span>
                <span className={styles.productText}>
                  {category.title}
                  <small>{category.description}</small>
                </span>
                <span className={styles.rightArrowIcon}>
                  <FontAwesomeIcon
                    icon={faAngleRight}
                    className={styles.icon}
                  />
                </span>
                <div className={styles.productList}>
                  {category.products.map(prod => {
                    return (
                      <a
                        className={
                          prod.name === 'LivePolls'
                            ? styles.activeProductBlock
                            : styles.productBlock
                        }
                        href={prod.url}
                        key={prod.name}
                      >
                        <div
                          className={
                            prod.name === 'LivePolls'
                              ? styles.activeProduct
                              : styles.productBorderTop
                          }
                          style={{
                            backgroundColor: category.color,
                          }}
                        ></div>
                        <div className={styles.product}>
                          <img
                            className={styles.productImg}
                            src={prod.icon}
                            alt={prod.name}
                            height={16}
                            width={16}
                          />
                          <img
                            className={styles.hoverImg}
                            src={prod.hoverIcon}
                            alt={prod.name}
                            height={16}
                            width={16}
                          />
                          <span>{prod.name}</span>
                        </div>
                      </a>
                    );
                  })}
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
