import React from 'react';
import { useParams } from 'react-router-dom';
import styles from './DashboardScreen.module.css';
import { DashboardReports } from './component/DashboardReports';

export const DashboardScreen = () => {
  const params = useParams<{ dashboardReportUid: string }>();
  const dashboardReportUid = params.dashboardReportUid!;
  const containerStyle: React.CSSProperties = { margin: '0' };

  return (
    <div className={styles.container} style={containerStyle}>
      <DashboardReports dashboardReportUid={dashboardReportUid} />
    </div>
  );
};
