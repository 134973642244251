import { WordCloudQuestionResponse } from 'src/interfaces/word-cloud-question-response.interface';

export const formatWordCloudResults = (
  answers: WordCloudQuestionResponse[] | undefined,
) => {
  if (!answers) {
    return [];
  }

  answers.sort((answer1, answer2) => {
    return answer2.weight - answer1.weight;
  });

  return answers;
};
