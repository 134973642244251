import { useState } from 'react';
import { FilterByProfileQuestionReportList } from './FilterByProfileQuestionReportList';
import { IAnswerWithColor } from '@livepolls/ui-components/src/interfaces/answer-with-color.interface';
import { ILiveFeedQuestionReport } from '@livepolls/ui-components/src/interfaces/livefeed-question-report';
import { IMultipleChoiceQuestionReport } from '@livepolls/ui-components/src/interfaces/multiple-choice-question-report';
import { ProfileQuestionReport } from '@livepolls/ui-components/src/interfaces/profile-question-report.interface';
import { IWordCloudQuestionReport } from '@livepolls/ui-components/src/interfaces/word-cloud-report.interface';
import { TeamLeaderboardRow } from '@livepolls/ui-components/src/interfaces/team-leaderboard-row';
import { LivePollQuestionReportList } from './LivePollQuestionReportList';

interface Props {
  livePollId: number;
  livePollSessionId: number;
  questionReports: (
    | IMultipleChoiceQuestionReport
    | ILiveFeedQuestionReport
    | IWordCloudQuestionReport
  )[];
  isQuiz: boolean;
  responseCount: number;
  profileQuestionReport?: ProfileQuestionReport[];
  showDifficultyLevels: boolean;
  enableQuestionDifficulty: boolean;
  teamLeaderboardRows?: TeamLeaderboardRow[];
}

export const LivePollQuestionReports = ({
  livePollId,
  livePollSessionId,
  questionReports,
  isQuiz,
  responseCount,
  showDifficultyLevels,
  enableQuestionDifficulty,
  teamLeaderboardRows,
}: Props) => {
  const [selectedProfileQuestionId] = useState<string | undefined>(undefined);
  const [answersWithColor] = useState<IAnswerWithColor[]>([]);

  const difficultyLevelIndicatorStyle: React.CSSProperties = {
    fontSize: '14px',
  };

  return (
    <>
      {selectedProfileQuestionId ? (
        <FilterByProfileQuestionReportList
          livePollId={livePollId}
          livePollSessionId={livePollSessionId}
          isQuiz={isQuiz}
          responseCount={responseCount}
          selectedProfileQuestionId={selectedProfileQuestionId}
          questionReports={questionReports}
          answersWithColor={answersWithColor}
          showDifficultyLevels={showDifficultyLevels}
          enableQuestionDifficulty={enableQuestionDifficulty}
          difficultyLevelIndicatorStyle={difficultyLevelIndicatorStyle}
        />
      ) : (
        <LivePollQuestionReportList
          livePollId={livePollId}
          livePollSessionId={livePollSessionId}
          isQuiz={isQuiz}
          responseCount={responseCount}
          questionReports={questionReports}
          showDifficultyLevels={showDifficultyLevels}
          enableQuestionDifficulty={enableQuestionDifficulty}
          teamLeaderboardRows={teamLeaderboardRows}
          difficultyLevelIndicatorStyle={difficultyLevelIndicatorStyle}
        />
      )}
    </>
  );
};
