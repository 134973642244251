import { UseMutationResult } from 'react-query';

import { LpDropdown } from '../dropdown/LpDropdown';
import { LiveFeedComment } from './components/LiveFeedComment';
import styles from './LpLiveFeedStats.module.css';
import { LiveFeedSvg } from '../svgImages/Livefeed.svg';
import { RanksSvg } from '../svgImages/Ranks.svg';
import { BookmarkSvg } from '../svgImages/Bookmark.svg';
import { LiveFeedStatsDropdownEnum } from '../../enums/live-feed-stats-dropdown.enum';
import { LiveFeedQuestionResponse } from '../../interfaces/livefeed-question-response';
import { LiveFeedPinUnpin } from '../../enums/livefeed-pin-unpin.enum';
import { LpPaginateDropdown } from '../pagination-dropdown/LpPaginationDropdown';
import { IPaginateDropdown } from '../../interfaces/paginate-dropdown.interface';
import { useCallback } from 'react';

interface Props {
  dropDownText: LiveFeedStatsDropdownEnum;
  liveFeedQuestionResponses: LiveFeedQuestionResponse[];
  onDropDownChange: (dropDownText: LiveFeedStatsDropdownEnum) => void;
  noResponsesText?: string;
  commentPinUnpin?: () => UseMutationResult<
    void,
    unknown,
    { commentId: number; pinUnpin: LiveFeedPinUnpin },
    unknown
  >;
  totalItems?: number;
  pager?: IPaginateDropdown;
  onPageChange?: (pager: IPaginateDropdown) => void;
  resultScreen?: boolean;
  text: {
    liveFeed: string;
    topVoted: string;
    bookmarked: string;
    comments: string;
    showing: string;
    showUpTo: string;
    of: string;
    to: string;
    invalidDropdownError: string;
    noComments: string;
    noVotesForComments: string;
    noBookmarkedComments: string;
  };
}

export const LpLiveFeedStats = ({
  dropDownText,
  liveFeedQuestionResponses,
  onDropDownChange,
  noResponsesText,
  commentPinUnpin,
  totalItems,
  pager,
  onPageChange,
  resultScreen,
  text,
}: Props) => {
  const showPagination =
    totalItems != null &&
    totalItems > 0 &&
    pager != null &&
    onPageChange != null;

  const handleDropdownChange = (dropDownText: LiveFeedStatsDropdownEnum) => {
    onDropDownChange(dropDownText);
  };

  const getDropdownText = useCallback(
    (dropDownText: LiveFeedStatsDropdownEnum): string => {
      switch (dropDownText) {
        case LiveFeedStatsDropdownEnum.LIVE_FEED:
          return text.liveFeed;
        case LiveFeedStatsDropdownEnum.TOP_VOTED:
          return text.topVoted;
        case LiveFeedStatsDropdownEnum.BOOKMARKED:
          return text.bookmarked;

        default:
          throw new Error(text.invalidDropdownError);
      }
    },
    [text],
  );

  const getNoResponsesText = useCallback(
    (dropDownText: LiveFeedStatsDropdownEnum, noResponsesText?: string) => {
      switch (dropDownText) {
        case LiveFeedStatsDropdownEnum.LIVE_FEED:
          return noResponsesText || text.noComments;
        case LiveFeedStatsDropdownEnum.TOP_VOTED:
          return text.noVotesForComments;
        case LiveFeedStatsDropdownEnum.BOOKMARKED:
          return text.noBookmarkedComments;

        default:
          throw new Error(text.invalidDropdownError);
      }
    },
    [text],
  );

  const fixedHeightClassNoResponseDiv = !!resultScreen
    ? styles.resultScreenNoResponseDivHeight
    : styles.otherScreensNoResponseDivHeight;

  const noResponsesDiv = (
    <div className={`${styles.idleContent} ${fixedHeightClassNoResponseDiv}`}>
      <span>{getNoResponsesText(dropDownText, noResponsesText)}</span>
    </div>
  );

  const dropDownForDesktopScreens = (
    <div className={styles.liveFeedViewDropdown}>
      <LpDropdown
        text={getDropdownText(dropDownText)}
        leftIcon={getDropdownIconByText(
          dropDownText,
          text.invalidDropdownError,
        )}
        customDropdownBtnClass={styles.dropDown}
      >
        <div
          className={styles.dropDownItem}
          onClick={() =>
            handleDropdownChange(LiveFeedStatsDropdownEnum.LIVE_FEED)
          }
        >
          <span className={styles.dropDownItemIcon}>{LiveFeedSvg()}</span>
          <span>{getDropdownText(LiveFeedStatsDropdownEnum.LIVE_FEED)}</span>
        </div>
        <div
          className={styles.dropDownItem}
          onClick={() =>
            handleDropdownChange(LiveFeedStatsDropdownEnum.TOP_VOTED)
          }
        >
          <span className={styles.dropDownItemIcon}>{RanksSvg()}</span>
          <span>{getDropdownText(LiveFeedStatsDropdownEnum.TOP_VOTED)}</span>
        </div>
        <div
          className={styles.dropDownItem}
          onClick={() =>
            handleDropdownChange(LiveFeedStatsDropdownEnum.BOOKMARKED)
          }
        >
          <span className={styles.dropDownItemIcon}>{BookmarkSvg()}</span>
          <span>{getDropdownText(LiveFeedStatsDropdownEnum.BOOKMARKED)}</span>
        </div>
      </LpDropdown>
    </div>
  );

  const dropDownForMobileScreens = (
    <>
      <div
        className={`${styles.mobileView} ${styles.liveFeedViewSelectorMobile}`}
      >
        <span
          className={`${styles.viewSelectorIconMobile} ${getSelectedIconClass(
            dropDownText,
            LiveFeedStatsDropdownEnum.LIVE_FEED,
          )}`}
          onClick={() =>
            handleDropdownChange(LiveFeedStatsDropdownEnum.LIVE_FEED)
          }
        >
          {LiveFeedSvg()}
        </span>
        <span
          className={`${styles.viewSelectorIconMobile} ${getSelectedIconClass(
            dropDownText,
            LiveFeedStatsDropdownEnum.TOP_VOTED,
          )}`}
          onClick={() =>
            handleDropdownChange(LiveFeedStatsDropdownEnum.TOP_VOTED)
          }
        >
          {RanksSvg()}
        </span>
        <span
          className={`${styles.viewSelectorIconMobile} ${getSelectedIconClass(
            dropDownText,
            LiveFeedStatsDropdownEnum.BOOKMARKED,
          )}`}
          onClick={() =>
            handleDropdownChange(LiveFeedStatsDropdownEnum.BOOKMARKED)
          }
        >
          {BookmarkSvg()}
        </span>
      </div>
      <div className={`${styles.mobileView} ${styles.horizontalLine}`}></div>
    </>
  );

  const fixedHeightClass = !!resultScreen
    ? styles.resultScreenContainerListHeight
    : styles.otherScreensContainerListHeight;

  return (
    <>
      <div className={styles.container}>
        {dropDownForDesktopScreens}

        {dropDownForMobileScreens}

        {showPagination && (
          <div className={styles.paginationContainer}>
            <LpPaginateDropdown
              totalItems={totalItems}
              pager={pager!}
              onPageChange={onPageChange!}
              customDropdownContentClass={styles.dropDownContentClass}
              text={{
                showing: text.showing,
                showUpto: text.showUpTo,
                of: text.of,
                to: text.to,
              }}
            />
          </div>
        )}
      </div>
      {liveFeedQuestionResponses.length === 0 && noResponsesDiv}
      {liveFeedQuestionResponses.length > 0 && (
        <>
          <div className={`${styles.commentList} ${fixedHeightClass}`}>
            {liveFeedQuestionResponses.map(response => (
              <LiveFeedComment
                key={response.id}
                liveFeedResponse={response}
                commentPinUnpin={commentPinUnpin}
              />
            ))}
          </div>
        </>
      )}
    </>
  );
};

const getDropdownIconByText = (
  dropDownText: LiveFeedStatsDropdownEnum,
  errorText: string,
): React.ReactElement => {
  switch (dropDownText) {
    case LiveFeedStatsDropdownEnum.LIVE_FEED:
      return LiveFeedSvg();
    case LiveFeedStatsDropdownEnum.TOP_VOTED:
      return RanksSvg();
    case LiveFeedStatsDropdownEnum.BOOKMARKED:
      return BookmarkSvg();

    default:
      throw new Error(errorText);
  }
};

const getSelectedIconClass = (
  dropDownText: LiveFeedStatsDropdownEnum,
  iconLabel: LiveFeedStatsDropdownEnum,
): string => {
  return dropDownText === iconLabel ? styles.selectedIcon : '';
};
