import styles from './LpQuestionTitle.module.css';
import { CSSProperties, useState } from 'react';
import { LpImagePopup } from '../image-popup/LpImagePopup';
import maximize from '../../images/maximize.svg';
import { LpDifficultyLevelIndicator } from '../difficulty-level-indicator/LpDifficultyLevelIndicator';
import { useTranslation } from 'react-i18next';
import { QuestionDifficultyLevel } from 'src/enums/question-difficulty-level.enum';

interface Props {
  currentQuestionNumber: number;
  title?: string;
  image?: { url: string };
  showDifficultyLevels?: boolean;
  difficultyLevel?: QuestionDifficultyLevel;
  difficultyLevelIndicatorStyle?: CSSProperties;
}
export const LpQuestionTitle = ({
  currentQuestionNumber,
  title,
  image,
  showDifficultyLevels,
  difficultyLevel,
  difficultyLevelIndicatorStyle
}: Props) => {
  const { t } = useTranslation();
  const [displayImagePopup, setDisplayImagePopup] = useState<boolean>(false);
  const closePopup = () => {
    setDisplayImagePopup(false);
  };
  return (
    <>
      <div className={styles.container}>
        <div className={styles.titleContainer}>
          <span
            className={styles.currentQuestionNumber}
            data-testid="questionNumber"
          >
            {currentQuestionNumber}.
          </span>
          <div className={styles.title} data-testid="questionTitle">
            {title || 'Untitled'}
          </div>
          {showDifficultyLevels && (
            <div className={styles.difficultyLevelIndicatorContainer}>
              <LpDifficultyLevelIndicator
                questionDifficultyLevel={difficultyLevel!}
                text={{
                  easy: t('easy'),
                  normal: t('normal'),
                  hard: t('hard'),
                }}
                difficultyLevelIndicatorStyle={difficultyLevelIndicatorStyle}
              />
            </div>
          )}
        </div>
        <div>
          {image && (
            <div
              onClick={() => setDisplayImagePopup(true)}
              className={styles.imageContainer}
            >
              <img
                src={image.url}
                alt="question pic"
                className={styles.image}
              />
              <div className={styles.imageOverlay}>
                <img
                  src={maximize}
                  alt="maximize question pic"
                  width={14}
                  className={styles.maximize}
                />
              </div>
            </div>
          )}
        </div>
        {displayImagePopup && (
          <LpImagePopup image={image} closeImagePopup={closePopup} />
        )}
      </div>
    </>
  );
};
