interface Props {
  width: number;
  height: number;
}

export const TimerSvg = ({ width, height }: Props) => {
  const image = (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 23"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 0C8.25 0 7 0.75 7 0.75V3H12V0.75C12 0.75 10.75 0 9.5 0ZM10.6875 8.75V13.5H13.0625V15.875H8.3125V8.75H10.6875ZM0 13.5C0 18.7467 4.2533 23 9.5 23C14.7467 23 19 18.7467 19 13.5C19 8.25329 14.7467 4 9.5 4C4.2533 4 0 8.25329 0 13.5Z"
        fill="currentColor"
      />
    </svg>
  );
  return image;
};
