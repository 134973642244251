import styles from './LpPanelContainer.module.css';
interface Props {
  children: React.ReactNode;
  customContainerClass?: string;
}
export const LpPanelContainer = ({ children, customContainerClass }: Props) => {
  return (
    <div className={`${styles.panelContainer} ${customContainerClass}`}>
      <div className={styles.borderBottom}></div>
      {children}
    </div>
  );
};
