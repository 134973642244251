import { LpQuestionResultChart } from '../../question-result-chart/LpQuestionResultChart';
import { ProfileQuestionReport } from '../../../interfaces/profile-question-report.interface';
import styles from './LpProfileQuestionReports.module.css';
import { LpQuestionTitle } from '../../question-title/LpQuestionTitle';

interface Props {
  questionReports: ProfileQuestionReport[];
  responseCount: number;
  text: {
    noVotes: string;
    outOf: string;
    votes: string;
    noDataAvailable: string;
    initializing: string;
  };
}
export const LpProfileQuestionReports = ({ questionReports, text }: Props) => {
  const isQuiz = false;

  return (
    <>
      {questionReports.map(questionReport => (
        <div
          className={styles.chartRow}
          data-testid="profile-questions-report"
          key={questionReport.id}
        >
          <LpQuestionTitle
            currentQuestionNumber={questionReport.currentQuestionNumber || 0}
            title={questionReport.title}
            image={questionReport.image}
          />
          <div>
            <LpQuestionResultChart
              isQuiz={isQuiz}
              answerStats={questionReport.answers}
              text={text}
            />
          </div>
        </div>
      ))}
    </>
  );
};
