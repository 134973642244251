export const BackButtonSvg = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12 1L5.00001 8L12 15" stroke="currentColor" strokeWidth="2" />
    </svg>
  );
};
