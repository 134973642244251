import { ILivePollSession } from '../../interfaces/livepoll-session.interface';
import { secondsToHms } from '../../utils/DateTimeUtil';
import styles from './LpResponseCountAndTime.module.css';
import { ResponseCountSvg } from '../svgImages/ResponseCount.svg';
import { TimerSvg } from '../svgImages/Timer.svg';
import { BackButtonSvg } from '../svgImages/BackButton.svg';
import { ShareIconSvg } from '../svgImages/ShareIcon.svg';
import { LpNewToolTip } from '../new-tooltip/LpNewToolTip';
import { useEffect, useState } from 'react';
import { LpToast } from '../toast/LpToast';
import { SHOW_TOAST_TIMEOUT } from '../../constants/app-common.constants';

interface Props {
  livePollSession: ILivePollSession;
  responseCount: number;
  onBackButtonClick?(): void;
  shareReportUrl?: string;
}

export const LpResponseCountAndTime = (props: Props) => {
  const { livePollSession, responseCount, onBackButtonClick, shareReportUrl } =
    props;

  const [showToast, setShowToast] = useState<boolean>();

  const timeTaken = calculateTimeTaken(livePollSession);
  const sessionName = livePollSession.name || 'Untitled';

  const backButtonClick = () => {
    onBackButtonClick && onBackButtonClick();
  };

  const handleCopyShareIconLink = () => {
    if (!shareReportUrl) {
      return;
    }

    if (navigator.clipboard && window.isSecureContext) {
      setShowToast(true);
      navigator.clipboard.writeText(shareReportUrl);
    }
  };

  useEffect(() => {
    let timeoutId = null;
    if (showToast) {
      timeoutId = setTimeout(() => {
        setShowToast(false);
      }, SHOW_TOAST_TIMEOUT);
    }

    return () => {
      timeoutId && clearTimeout(timeoutId);
    };
  }, [showToast]);

  return (
    <div className={styles.headerContainer}>
      {showToast && (
        <LpToast
          text="Results URL copied to clipboard"
          onClose={() => setShowToast(false)}
        />
      )}
      <div className={styles.sessionName}>
        {onBackButtonClick && (
          <span
            className={styles.rightIcon}
            onClick={backButtonClick}
            data-testid="backIcon"
          >
            <BackButtonSvg />
          </span>
        )}

        {sessionName}
      </div>
      <div className={styles.responseCountContainer}>
        <span className={styles.responseCountIcon}>
          <ResponseCountSvg width={16} height={16} />
        </span>
        <span className={styles.responseCount} data-testid="response-count">
          {responseCount}
        </span>
      </div>
      {timeTaken && (
        <div className={styles.sessionTimeContainer}>
          <span className={styles.timerSvg}>
            <TimerSvg width={16} height={16} />
          </span>
          <span className={styles.sessionTime} data-testid="time taken">
            {timeTaken}
          </span>
          <span className={styles.sessionTimePostfix}>min</span>
        </div>
      )}
      {shareReportUrl && (
        <div
          className={styles.shareIconContainer}
          onClick={handleCopyShareIconLink}
          data-testid="share-icon"
        >
          <LpNewToolTip textOnHover="Share" showToolTip={true}>
            <div className={styles.shareIconInnerContainer}>
              <span className={styles.responseCountIcon}>
                <ShareIconSvg width={16} height={16} />
              </span>
            </div>
          </LpNewToolTip>
        </div>
      )}
    </div>
  );
};

function calculateTimeTaken(session: ILivePollSession): string | null {
  if (!session.completedAt) {
    return null;
  }

  const startTime = new Date(session.createdAt).getTime();
  const endTime = new Date(session.completedAt).getTime();
  const diffInSeconds = (endTime - startTime) / 1000;

  return secondsToHms(diffInSeconds);
}
