import styles from './LpPanelHeading.module.css';

interface Props {
  isActive: boolean;
  onPanelHeadClick: () => void;
  children: React.ReactElement;
  customeClassActivePanelHeading?: string;
  customeClassPanelHeading?: string;
}

export const LpPanelHeading = ({
  isActive,
  onPanelHeadClick,
  children,
  customeClassActivePanelHeading,
  customeClassPanelHeading,
}: Props) => {
  return (
    <>
      <div
        className={`${styles.panelHeading} ${customeClassPanelHeading} ${
          isActive
            ? styles.activePanel + ' ' + customeClassActivePanelHeading
            : ''
        }`}
        onClick={onPanelHeadClick}
      >
        {children}
      </div>
    </>
  );
};
