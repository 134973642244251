export const RibbonSvg = () => {
  const image = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="24"
      viewBox="0 0 18 24"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M14,29.7083195 C12.1587519,28.0603579 11,25.6654994 11,23 C11,18.0294373 15.0294373,14 20,14 C24.9705627,14 29,18.0294373 29,23 C29,25.6654994 27.8412481,28.0603579 26,29.7083195 L26,38 L20,35 L14,38 L14,29.7083195 L14,29.7083195 Z M20,26 L23.5267115,27.854102 L22.8531695,23.927051 L25.7063391,21.145898 L21.7633558,20.572949 L20,17 L18.2366442,20.572949 L14.2936609,21.145898 L17.1468305,23.927051 L16.4732885,27.854102 L20,26 Z"
        transform="translate(-11 -14)"
      />
    </svg>
  );
  return image;
};
