import React, { useCallback, useEffect, useRef, useState } from 'react';
import Highcharts from 'highcharts';
import styles from './LpQuestionResultChart.module.css';
import { getLightColor } from '../../utils/color.util';
import { LpSpinner } from '../spinner/LpSpinner';

interface Props {
  isQuiz: boolean;
  answerStats: {
    id: string;
    count: number;
    text?: string;
    isCorrect: boolean;
    image?: { url: string };
  }[];
  barColor?: string;
  highlightBarColor?: string;
  chartColor?: string;
  text: {
    noVotes: string;
    outOf: string;
    votes: string;
    noDataAvailable: string;
    initializing: string;
  };
}
interface Answer {
  isCorrect?: boolean;
  count: number;
  id: string;
  image?: { url: string };
}

const getTopVotedAnswer = (answers: Answer[]) => {
  let topVotedAnswer = answers[0];
  answers.forEach((answer: Answer) => {
    if (topVotedAnswer.count < answer.count) {
      topVotedAnswer = answer;
    }
  });
  return topVotedAnswer;
};

const makeHighLightedAnswers = (
  isQuiz: boolean,
  answerStats: Answer[],
  highlightBarColor: string,
  barColor: string,
) => {
  const highLightedAnswers: Answer[] = [];
  const countsWithColor = answerStats.map((answerStat: Answer) => {
    if (answerStat.id === 'NA') {
      return {
        y: answerStat.count,
        color: '#ccc',
      };
    } else if (!isQuiz) {
      const topVotedAnswer = getTopVotedAnswer(answerStats);

      highLightedAnswers.push(topVotedAnswer);
      return {
        y: answerStat.count,
        color:
          topVotedAnswer.id === answerStat.id ? highlightBarColor : barColor,
      };
    } else {
      if (answerStat.isCorrect) {
        highLightedAnswers.push(answerStat);
      }
      return {
        y: answerStat.count,
        color: answerStat.isCorrect ? highlightBarColor : barColor,
      };
    }
  });
  return { countsWithColor, highLightedAnswers };
};

const getYAxisMaxCount = (maxAnswerCount: number) => {
  if (maxAnswerCount < 10) {
    return maxAnswerCount + 3;
  } else {
    const increasedLength = Math.round(maxAnswerCount * 0.3);
    const lengthOfYAxis = maxAnswerCount + increasedLength;
    return lengthOfYAxis;
  }
};

const getResponseCountInPercent = (
  totalAnswers: number,
  responseCount: number,
): number => {
  if (totalAnswers === 0) {
    return 0;
  }
  return Math.round((responseCount / totalAnswers) * 100);
};

export const LpQuestionResultChart = ({ isQuiz, answerStats, text }: Props) => {
  const ref = React.useRef<HTMLDivElement | null>(null);
  const highChartsRef = useRef<any>(null);
  const mainColor = document.documentElement.style.getPropertyValue('--main');
  const highlightBarColor = mainColor || '#1b3380';
  const barColor = getLightColor(highlightBarColor);
  const chartColor = '#545E6B';
  const [isInitialized, setIsInitialized] = useState<boolean>(false);

  const scrollHandler = useCallback(() => {
    if (highChartsRef.current) {
      (highChartsRef.current as any).tooltip.hide();
    }
  }, []);

  useEffect(() => {
    document.body.addEventListener('scroll', scrollHandler, { capture: true });
    return () => {
      document.body.removeEventListener('scroll', scrollHandler, {
        capture: true,
      });
    };
  }, [scrollHandler]);

  React.useEffect(() => {
    if (!ref.current) {
      return;
    }

    if (!isInitialized) {
      setIsInitialized(true);
    }

    const checkIsLastOption = (answer: Answer) => {
      const totalAnswers = answerStats.length;
      const index = answerStats.findIndex(
        answerStat => answerStat.id === answer.id,
      );

      if (totalAnswers === index + 1) {
        return { isLast: true, imageUrl: !!answer!.image?.url };
      }
      return { isLast: false, imageUrl: !!answer!.image?.url };
    };

    const { countsWithColor, highLightedAnswers } = makeHighLightedAnswers(
      isQuiz,
      answerStats,
      highlightBarColor,
      barColor,
    );

    const maxAnswerCount = answerStats.reduce(
      (answer1, answer2) =>
        (answer1 = answer1 > answer2.count ? answer1 : answer2.count),
      0,
    );

    const yAxisMaxCount = getYAxisMaxCount(maxAnswerCount);
    const totalResponses = answerStats.reduce(
      (accumulator, currAnswer) => accumulator + currAnswer.count,
      0,
    );

    highChartsRef.current = Highcharts.chart(ref.current, {
      tooltip: {
        hideDelay: 0,
        backgroundColor: '#545e6b',
        borderColor: '#545e6b',
        borderRadius: 8,
        outside: true,
        style: { fontFamily: 'Fira Sans', color: '#fff', border: 'none' },
        formatter: function () {
          if (totalResponses === 0) {
            return `${text.noVotes}`;
          }
          return `${this.y} ${text.outOf} ${totalResponses} ${text.votes}`;
        },
      },
      chart: {
        height: calculateHeight(answerStats.length),
        type: 'bar',
        backgroundColor: 'rgba(0,0,0,0)',
        marginLeft: 350,
      },
      title: {
        text: '',
      },
      xAxis: {
        categories: answerStats.map(answerStat => answerStat.text || ''),
        labels: {
          useHTML: true,
          style: {
            color: chartColor,
            fontSize: '16',
            fontFamily: 'Fira Sans, sans-serif',
          },
          formatter: function () {
            const sequenceNumber = '';

            let result: Answer;
            answerStats.forEach((answer, index) => {
              if (index === this.pos) {
                result = answer;
              }
            });

            const highLightedAnswer = highLightedAnswers.find(
              item => item.id === result.id,
            );
            const boldRow = highLightedAnswer ? true : false;
            const text = (sequenceNumber + this.value) as string;
            const fontFamily = styles.fontFamily;

            const image = getImage(result!);
            const { isLast, imageUrl } = checkIsLastOption(result!);

            const truncatedText =
              text.length > 125 ? text.substring(0, 125) + '...' : text;
            const classNames = getClassNames(isLast, imageUrl, text.length);

            if (boldRow) {
              const checkMarkImage = isQuiz ? getCheckMarkImage() : '';
              return `<div class=${styles.boldRow}>
              ${checkMarkImage}<div style="${fontFamily};" class=${styles.row}>
              <div style="color:${highlightBarColor}" class=${
                styles.content
              }>${text}</div>
              ${image}
              <div class="${classNames}">${truncatedText}</div>
               ${text.length > 0 ? `<div class=${styles.arrow}></div>` : ''}
              </div>
              </div>`;
            } else {
              const paddingLeft = isQuiz
                ? 'padding-left: 35px'
                : 'padding-left: 0';
              const left = isQuiz ? 'left: 50px' : 'left: 10px';

              return `<div style="${paddingLeft};${fontFamily};" class=${
                styles.inCorrectOrNonTopVotedRow
              }>
                <div class=${styles.content}>${text} </div>
                    ${image}
                    <div class="${classNames}">${truncatedText}</div>
                     ${
                       text.length > 0
                         ? `<div class="${styles.arrow}" style="${left}"></div>`
                         : ''
                     }                 
                </div>`;
            }
          },
          align: 'left',
          x: -350,
        },
        lineWidth: 1.5,
        lineColor: chartColor,
      },
      yAxis: {
        max: yAxisMaxCount,
        min: 0,
        title: {
          text: '',
        },
        gridLineColor: 'transparent',

        lineWidth: 1.5,
        lineColor: chartColor,
        labels: {
          style: {
            color: chartColor,
            visibility: 'hidden',
          },
        },
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,

            color: '#9b9b9b',
            formatter: function () {
              let result: Answer;
              answerStats.forEach((answer, index) => {
                if (index === this.point.index) {
                  result = answer;
                }
              });

              const highLightedAnswer = highLightedAnswers.find(
                item => item.id === result.id,
              );
              const boldRow = highLightedAnswer ? true : false;

              const percent = `${getResponseCountInPercent(
                totalResponses,
                Number(this.point.y),
              )}%`;

              if (boldRow) {
                return `<span style="color:${highlightBarColor};font-weight: bold;">${percent}</span>`;
              } else {
                return `<span>${percent}</span>`;
              }
            },
            className: styles.dataLabel,
            style: {
              textOutline: '0',
              fontSize: '12px',
              fontWeight: '700',
            },
          },
          borderWidth: 0,
          animation: false,
        },
      },
      series: [
        {
          data: countsWithColor,
          type: 'column',
          color: barColor,
          pointWidth: 32,
          borderRadius: 2,
        },
      ],

      credits: {
        enabled: false,
      },
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 701,
              minWidth: 650,
            },
            chartOptions: {
              chart: {
                marginLeft: 280,
              },
              xAxis: {
                labels: {
                  x: -280,
                },
              },
            },
          },
          {
            condition: {
              maxWidth: 651,
              minWidth: 620,
            },
            chartOptions: {
              chart: {
                marginLeft: 260,
              },
              xAxis: {
                labels: {
                  x: -260,
                },
              },
            },
          },
          {
            condition: {
              maxWidth: 621,
              minWidth: 520,
            },
            chartOptions: {
              chart: {
                marginLeft: 240,
              },
              xAxis: {
                labels: {
                  x: -240,
                },
              },
            },
          },
          {
            condition: {
              maxWidth: 521,
              minWidth: 370,
            },
            chartOptions: {
              chart: {
                marginLeft: 200,
              },
              xAxis: {
                labels: {
                  x: -200,
                },
              },
            },
          },
          {
            condition: {
              maxWidth: 371,
            },
            chartOptions: {
              chart: {
                marginLeft: 180,
              },
              xAxis: {
                labels: {
                  x: -180,
                },
              },
            },
          },
        ],
      },
    });
  }, [
    answerStats,
    barColor,
    chartColor,
    highlightBarColor,
    isQuiz,
    isInitialized,
    text,
  ]);

  const getClassNames = (
    isLast: boolean,
    imageUrl: boolean,
    textLength: number,
  ) => {
    if (textLength === 0) {
      return;
    }

    if (isLast && imageUrl) {
      return `${styles.toolTipText} ${styles.toolTipTextForLastChildWithImage}`;
    } else if (isLast && !imageUrl) {
      return `${styles.toolTipText} ${styles.toolTipTextForLastChild}`;
    } else if (imageUrl) {
      return `${styles.toolTipText} ${styles.toolTipTextWithImage}`;
    }
    return `${styles.toolTipText}`;
  };

  const getCheckMarkImage = () => {
    const checkmark = `<div style="width: 35px">
      <span class=${styles.checkmark}>        
        <div class=${styles.checkmark_stem}></div>
        <div class=${styles.checkmark_kick}></div>
      </span>
    </div>`;
    return checkmark;
  };

  const calculateHeight = (numberOfAnswers: number) => {
    if (numberOfAnswers > 0) {
      return numberOfAnswers * 70;
    }
    return 140;
  };

  const getImage = (answer: Answer) => {
    const imageSrc = answer!.image?.url;
    const showImage = imageSrc ? true : false;

    if (!showImage) {
      return '<div></div>';
    }
    const image = `<div class=${styles.image}>      
    ${
      imageSrc !== undefined && showImage
        ? `<img src="${imageSrc}" class=${styles.answerImage} height="40px"
                 width="60px"/>`
        : ''
    }
    </div>`;
    return image;
  };

  return (
    <div className={styles.chartContainer}>
      {!isInitialized && (
        <div
          className={styles.chartInnerContainer}
          style={{
            height: `${calculateHeight(answerStats.length)}px`,
          }}
        >
          <LpSpinner message={`${text.initializing}...`} />
        </div>
      )}
      <div
        ref={ref}
        data-testid="questionResultChart"
        className={styles.chartInnerContainer}
      />
    </div>
  );
};
