import { LpFilterByProfileQuestionReport } from '@livepolls/ui-components/src/components/livepoll-question-reports/components/LpFilterByProfileQuestionReport';
import { LpWordCloudReport } from '@livepolls/ui-components/src/components/livepoll-question-reports/components/LpWordCloudReport';
import { LpSpinner } from '@livepolls/ui-components/src/components/spinner/LpSpinner';
import { QuestionDifficultyLevel } from '@livepolls/ui-components/src/enums/question-difficulty-level.enum';
import { QuestionType } from '@livepolls/ui-components/src/enums/question-type';
import { IAnswerWithColor } from '@livepolls/ui-components/src/interfaces/answer-with-color.interface';
import { IFilterByProfileQuestionReport } from '@livepolls/ui-components/src/interfaces/filter-by-profile-question-report.interface';
import { ILiveFeedQuestionReport } from '@livepolls/ui-components/src/interfaces/livefeed-question-report';
import { IMultipleChoiceQuestionReport } from '@livepolls/ui-components/src/interfaces/multiple-choice-question-report';
import { IWordCloudQuestionReport } from '@livepolls/ui-components/src/interfaces/word-cloud-report.interface';
import { CSSProperties, useEffect, useState } from 'react';
import { getApi } from 'src/utils/api.util';
import { LiveFeedReport } from './LiveFeedReport';
import { LpSwipeQuestionReport } from '@livepolls/ui-components/src/components/livepoll-question-reports/components/LpSwipeQuestionReport';
import { ISwipeQuestionReport } from '@livepolls/ui-components/src/interfaces/swipe-question-report.interface';

interface Props {
  livePollId: number;
  livePollSessionId: number;
  questionReports: (
    | IMultipleChoiceQuestionReport
    | ILiveFeedQuestionReport
    | IWordCloudQuestionReport
  )[];
  isQuiz: boolean;
  responseCount: number;
  answersWithColor: IAnswerWithColor[];
  selectedProfileQuestionId: string;
  showDifficultyLevels: boolean;
  enableQuestionDifficulty: boolean;
  difficultyLevelIndicatorStyle: CSSProperties;
}
export const FilterByProfileQuestionReportList = ({
  questionReports,
  isQuiz,
  responseCount,
  answersWithColor,
  livePollId,
  livePollSessionId,
  selectedProfileQuestionId,
  showDifficultyLevels,
  enableQuestionDifficulty,
  difficultyLevelIndicatorStyle,
}: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);
  const [filterByProfileQuestionReports, setFilterByProfileQuestionReports] =
    useState<IFilterByProfileQuestionReport[] | null>(null);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const payload = await getApi(
          `/take/${livePollId}/livepoll-sessions/${livePollSessionId}/report/profile-questions/${selectedProfileQuestionId}/filter-report`,
        );
        setFilterByProfileQuestionReports(payload);
      } catch (err: any) {
        setError(err);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [livePollId, livePollSessionId, selectedProfileQuestionId]);

  if (isLoading) {
    return <LpSpinner message="Loading..." />;
  }

  if (error) {
    return <div>Oops an error occurred ({error.message}).</div>;
  }

  const findReportForCurrentQuestion = (
    livePollQuestionId: string,
  ): IFilterByProfileQuestionReport => {
    const profileQuestionFilterReportForOneQuestion =
      filterByProfileQuestionReports?.find(
        filterByProfileQuestionReport =>
          filterByProfileQuestionReport.livePollQuestionId ===
          livePollQuestionId,
      );

    if (!profileQuestionFilterReportForOneQuestion)
      throw new Error(
        `LivePoll question with id: ${livePollQuestionId} not found`,
      );

    return {
      ...profileQuestionFilterReportForOneQuestion,
    };
  };
  return (
    <>
      {questionReports.map(questionReport => {
        const { id, type } = questionReport;
        if (type === QuestionType.CHOICE) {
          const choiceQuestionReport =
            questionReport as IMultipleChoiceQuestionReport;
          const difficultyLevel =
            enableQuestionDifficulty &&
            choiceQuestionReport.difficultyLevel != null
              ? choiceQuestionReport.difficultyLevel
              : QuestionDifficultyLevel.NORMAL;

          return (
            <div key={id}>
              <LpFilterByProfileQuestionReport
                key={id}
                isQuiz={isQuiz}
                responseCount={responseCount}
                questionReport={questionReport as IMultipleChoiceQuestionReport}
                filterByProfileQuestionReport={findReportForCurrentQuestion(id)}
                answersWithColor={answersWithColor}
                showDifficultyLevels={showDifficultyLevels}
                difficultyLevel={difficultyLevel}
                difficultyLevelIndicatorStyle={difficultyLevelIndicatorStyle}
              />
            </div>
          );
        } else if (type === QuestionType.LIVE_FEED) {
          return (
            <LiveFeedReport
              key={id}
              livePollId={livePollId}
              livePollSessionId={livePollSessionId}
              liveFeedQuestionReport={questionReport as ILiveFeedQuestionReport}
            />
          );
        } else if (type === QuestionType.WORD_CLOUD) {
          return (
            <LpWordCloudReport
              key={id}
              wordCloudQuestionReport={
                questionReport as IWordCloudQuestionReport
              }
              text={{
                mentions: 'Mentions',
                noDataAvailable: 'No Data Available',
              }}
            />
          );
        } else if (type === QuestionType.SWIPE) {
          return (
            <LpSwipeQuestionReport
              key={id}
              questionReport={questionReport as ISwipeQuestionReport}
              text={{
                initializing: 'Initializing',
                noDataAvailable: 'Data not available',
              }}
              isQuiz={isQuiz}
            />
          );
        } else {
          throw new Error('Incorrect question type');
        }
      })}
    </>
  );
};
