interface Props {
  width: number;
  height: number;
}

export const ShareIconSvg = ({ width, height }: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-label="share icon"
    >
      <path
        d="M5.33333 1C6.06971 1 6.66667 1.63959 6.66667 2.42857C6.66667 3.21755 6.06971 3.85714 5.33333 3.85714L2.66667 3.85714L2.66667 17.1429H16V14.2857C16 13.4967 16.597 12.8571 17.3333 12.8571C18.0697 12.8571 18.6667 13.4967 18.6667 14.2857V17.1429C18.6667 18.7208 17.4728 20 16 20L2.66667 20C1.19391 20 -1.28753e-07 18.7208 0 17.1429L1.16564e-06 3.85714C1.29439e-06 2.27919 1.19391 1 2.66667 1L5.33333 1Z"
        fill="currentColor"
      />
      <path
        d="M12.6549 3.96826H14.1257V0L20 6.34921L14.1257 12.6984L14.1257 8.73016H12.6531C8.26666 8.73016 6.06667 11.9048 5.33333 14.2857C5.33333 8.73016 7.53333 3.96825 12.6549 3.96826Z"
        fill="currentColor"
      />
    </svg>
  );
};
