import styles from './LpMultipleChoiceQuestionLeaderboard.module.css';
import { IMultipleChoiceQuestionReport } from '../../../interfaces/multiple-choice-question-report';
import { RibbonSvg } from '../../svgImages/Ribbon.svg';
// import { LpPercentageCircle } from '../../percentage-circle/LpPercentageCircle';
import { LpToolTip } from '../../tool-tip/LpToolTip';

interface Props {
  responseCount: number;
  questionReport: IMultipleChoiceQuestionReport;
}

export const LpMultipleChoiceQuestionLeaderboard = ({
  responseCount,
  questionReport,
}: Props) => {
  const { leaderboard } = questionReport;

  // const mainColor = document.documentElement.style.getPropertyValue('--main');
  // const color = mainColor ? mainColor : '#1B3380';

  if (leaderboard && leaderboard.length > 0) {
    const leaderboardRows = leaderboard.map((r: any, i) => (
      <div
        key={i}
        className={i === 0 ? styles.questionLeaderboardFirst : ''}
        data-testid="topRespondentsForQuestion"
      >
        {i === 0 && (
          <span className={styles.ribbonImage}>
            <RibbonSvg />
          </span>
        )}
        <LpToolTip textOnHover={getHoverText(i, r.respondentName)}>
          <div>
            <div className={styles.questionLeaderboardName}>
              {r.respondentName}
            </div>
            <div className={styles.questionLeaderboardScore}>{r.score}</div>
          </div>
        </LpToolTip>
      </div>
    ));

    return (
      <div className={styles.questionLeaderboard}  data-testid="responseRateForQuestion">
        {leaderboardRows}
        {/* <div className={styles.questionResponseRateRow}>
          <span
            className={styles.questionResponseRate}
            data-testid="responseRateForQuestion"
          >
            <LpToolTip textOnHover="Percentage of respondents answered the question">
              <LpPercentageCircle
                color={color}
                radius={16}
                percentage={calculateResponseRate(responseCount, answerStats)}
                stroke={2}
                fontSize={12}
              />
            </LpToolTip>
          </span>
        </div> */}
      </div>
    );
  }
  return null;
};

// function calculateResponseRate(
//   responseCount: number,
//   answerStats: { count: number }[],
// ) {
//   if (responseCount === 0) {
//     return 0;
//   }

//   const questionResponseCount = answerStats.reduce(
//     (prevCount, currentItem) => prevCount + currentItem.count,
//     0,
//   );
//   return Math.round((questionResponseCount * 100) / responseCount);
// }

function getHoverText(index: number, respondentName: string) {
  const rank = index + 1;
  return `Rank ${rank}`;
}
