import { LpLeaderboard } from '@livepolls/ui-components/src/components/leaderboard/LpLeaderboard';
import { LpProfileQuestionReports } from '@livepolls/ui-components/src/components/livepoll-question-reports/components/LpProfileQuestionReports';
import { LpPanelContainer } from '@livepolls/ui-components/src/components/panel/LpPanelContainer';
import { LpPanelHeading } from '@livepolls/ui-components/src/components/panel/LpPanelHeading';
import { DashboardPanelType } from '@livepolls/ui-components/src/enums/dashboard-panel-type.enum';
import { LivePollType } from '@livepolls/ui-components/src/enums/livepoll-type.enum';
import { ILivePollSessionReport } from '@livepolls/ui-components/src/interfaces/livepoll-session-report.interface';
import { ILivePollSession } from '@livepolls/ui-components/src/interfaces/livepoll-session.interface';
import {
  transformLeaderboardToPager,
  transformTeamLeaderboardToPager,
} from '@livepolls/ui-components/src/utils/generate-leaderboard-row';
import { useState } from 'react';
import styles from './DashboardReportsForMobile.module.css';
import { LivePollQuestionReports } from './LivePollQuestionReports';

interface Props {
  livePollSession: ILivePollSession;
  livePoll: { id: number; type: LivePollType };
  livePollSessionReport: ILivePollSessionReport;
}
export const DashboardReportsForMobile = ({
  livePollSession,
  livePoll,
  livePollSessionReport,
}: Props) => {
  const [panelType, setPanelType] = useState<DashboardPanelType>(
    DashboardPanelType.LIVEPOLL_QUESTIONS,
  );
  const isProfileQuestionsActive =
    panelType === DashboardPanelType.PROFILE_QUESTIONS;

  const isLivePollQuestionsActive =
    panelType === DashboardPanelType.LIVEPOLL_QUESTIONS;

  const isLeaderboardActive = panelType === DashboardPanelType.LEADERBOARD;

  const isQuiz = livePoll.type === LivePollType.QUIZ;

  const {
    responseCount,
    questionReports,
    leaderboardRows,
    profileQuestionReports,
    teamLeaderboardRows,
  } = livePollSessionReport;

  const showDifficultyLevels =
    !!livePollSession.setting?.displayDifficultyLevels;
  const enableQuestionDifficulty =
    !!livePollSession.setting?.enableQuestionDifficulty;
  const isTeamsEnabled = !!livePollSession.setting?.teamSetting?.enabled;

  const handlePanelChange = (panelType: DashboardPanelType) => {
    setPanelType(panelType);
  };

  return (
    <>
      <div className={styles.left}>
        <div className={styles.leftWrapper}>
          <div className={styles.questionsContainer}>
            <div className={styles.sessionName}>
              {livePollSession.name || 'Untitled'}
            </div>

            <LpPanelContainer customContainerClass={styles.panelContainer}>
              <LpPanelHeading
                onPanelHeadClick={() =>
                  handlePanelChange(DashboardPanelType.LIVEPOLL_QUESTIONS)
                }
                isActive={isLivePollQuestionsActive}
              >
                <span className={styles.panelText}>LivePoll</span>
              </LpPanelHeading>
              {livePollSession.setting?.enableProfileQuestions && (
                <LpPanelHeading
                  onPanelHeadClick={() =>
                    handlePanelChange(DashboardPanelType.PROFILE_QUESTIONS)
                  }
                  isActive={isProfileQuestionsActive}
                >
                  <span className={styles.panelText}>Profile Qs</span>
                </LpPanelHeading>
              )}

              {isQuiz && (
                <LpPanelHeading
                  onPanelHeadClick={() =>
                    handlePanelChange(DashboardPanelType.LEADERBOARD)
                  }
                  isActive={isLeaderboardActive}
                >
                  <span className={styles.panelText}>Leaderboard</span>
                </LpPanelHeading>
              )}
            </LpPanelContainer>
            {isLivePollQuestionsActive && (
              <LivePollQuestionReports
                livePollId={livePollSession.livePollId}
                livePollSessionId={livePollSession.id}
                isQuiz={isQuiz}
                responseCount={responseCount}
                questionReports={questionReports}
                profileQuestionReport={profileQuestionReports}
                showDifficultyLevels={showDifficultyLevels}
                enableQuestionDifficulty={enableQuestionDifficulty}
                teamLeaderboardRows={teamLeaderboardRows}
              />
            )}
            {isProfileQuestionsActive && (
              <LpProfileQuestionReports
                questionReports={profileQuestionReports!}
                responseCount={responseCount}
                text={{
                  noVotes: 'No votes',
                  outOf: 'out of',
                  votes: 'votes',
                  initializing: 'Initializing',
                  noDataAvailable: 'No Data Available',
                }}
              />
            )}
            {isLeaderboardActive && (
              <div className={styles.dashboardReport}>
                {isTeamsEnabled && (
                  <LpLeaderboard
                    showSequenceNum={true}
                    pagewiseResults={transformTeamLeaderboardToPager(
                      teamLeaderboardRows || [],
                    )}
                    showPercentage={true}
                    height="30%"
                    showTeamColor={true}
                    text={{ noDataAvailable: 'No Data Available' }}
                  />
                )}
                <LpLeaderboard
                  showSequenceNum={true}
                  pagewiseResults={transformLeaderboardToPager(
                    leaderboardRows,
                    isTeamsEnabled,
                    {
                      individualLeaderboard: 'Individual Leaderboard',
                      leaderboard: 'Leaderboard',
                    },
                  )}
                  showPercentage={true}
                  height={isTeamsEnabled ? '70%' : '100%'}
                  showTeamColor={isTeamsEnabled}
                  text={{ noDataAvailable: 'No Data Available' }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
