import React, { useCallback, useEffect, useRef, useState } from 'react';
import Highcharts from 'highcharts';
import styles from './LpQuestionResultChartForTeams.module.css';
import { getLightColor } from '../../utils/color.util';
import { ITeam } from '../../interfaces/team.interface';
import { TeamResponse } from '../../interfaces/team-response.interface';
import { LpSpinner } from '../spinner/LpSpinner';
interface Props {
  barColor?: string;
  highlightBarColor?: string;
  chartColor?: string;
  responsesByTeam: TeamResponse[];
  teams: ITeam[];
  answerStats: Answer[];
  text: {
    noVotes: string;
    outOf: string;
    votes: string;
    noDataAvailable: string;
    initializing: string;
  };
}
interface Answer {
  isCorrect?: boolean;
  count: number;
  id: string;
  image?: { url: string };
  text?: string;
}

export const LpQuestionResultChartForTeams = ({
  teams,
  answerStats,
  responsesByTeam,
  text,
}: Props) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const mainColor = document.documentElement.style.getPropertyValue('--main');
  const highlightBarColor = mainColor || '#1f357b';
  const barColor = getLightColor(highlightBarColor);
  const chartColor = '#545E6B';
  const [chartInitialized, setChartInitialized] = useState<boolean>(false);
  const highChartsRef = useRef<any>(null);

  const scrollHandler = useCallback(() => {
    if (highChartsRef.current) {
      (highChartsRef.current as any).tooltip.hide();
    }
  }, []);

  useEffect(() => {
    document.body.addEventListener('scroll', scrollHandler, { capture: true });
    return () => {
      document.body.removeEventListener('scroll', scrollHandler, {
        capture: true,
      });
    };
  }, [scrollHandler]);

  const responsesWithCountAndColor = createSeries(
    responsesByTeam,
    answerStats,
    teams,
  );

  const maxAnswerCount = responsesByTeam?.reduce(
    (response1, response2) =>
      (response1 = response1 > response2.count ? response1 : response2.count),
    0,
  );

  const yAxisMaxCount = getYAxisMaxCount(maxAnswerCount);
  const totalResponses = answerStats.reduce(
    (accumulator, currAnswer) => accumulator + currAnswer.count,
    0,
  );

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    if (!chartInitialized) {
      setChartInitialized(true);
    }

    highChartsRef.current = Highcharts.chart(ref.current, {
      tooltip: {
        hideDelay: 0,
        backgroundColor: '#f5f5f5',
        borderColor: '#1f357b',
        outside: true,
        formatter: function () {
          if (totalResponses === 0) {
            return text.noVotes;
          }
          return `${this.y} ${text.outOf} ${totalResponses} ${text.votes}`;
        },
      },
      chart: {
        height: getPointsWidthAndHeight(answerStats.length).height,
        type: 'bar',
        backgroundColor: 'rgba(0,0,0,0)',
        marginLeft: 350,
      },
      title: {
        text: '',
      },
      xAxis: {
        categories: answerStats.map(answerStat => answerStat.text || ''),
        labels: {
          useHTML: true,
          style: {
            color: chartColor,
            fontSize: '16',
            fontFamily: 'Fira Sans, sans-serif',
          },
          formatter: function () {
            let result: Answer;
            answerStats.forEach((answer, index) => {
              if (index === this.pos) {
                result = answer;
              }
            });

            const text: string | number = this.value;
            const fontFamily = styles.fontFamily;

            const image = getImage(result!);
            const boldRow = isCorrect(result!);
            if (boldRow) {
              const checkMarkImage = getCheckMarkImage();
              const paddingLeft = 'padding-left: 0';

              return `<div class=${styles.boldRow}>
              ${checkMarkImage}<div style="${paddingLeft};${fontFamily};" class=${styles.row}>
              <div style="color:${highlightBarColor}" class=${styles.content}>${text}</div>
              ${image}
              <div class=${styles.toolTipText}>${text}</div>
              </div>
              </div>`;
            } else {
              const paddingLeft = 'padding-left: 35px';

              return `<div style="${paddingLeft};${fontFamily};" class=${styles.inCorrectOrNonTopVotedRow}>
                       <div  class=${styles.content}>${text} </div>
                       ${image}
                       <div class=${styles.toolTipText}>${text}</div>

                      </div>`;
            }
          },
          align: 'left',
          x: -350,
        },
        lineWidth: 1.5,
        lineColor: chartColor,
      },
      yAxis: {
        min: 0,
        title: {
          text: '',
        },
        max: yAxisMaxCount,
        gridLineColor: 'transparent',
        lineWidth: 1.5,
        lineColor: chartColor,
        labels: {
          style: {
            color: chartColor,
            visibility: 'hidden',
          },
        },
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
            color: '#9b9b9b',

            formatter: function () {
              let result: Answer;
              answerStats.forEach((answer, index) => {
                if (index === this.point.index) {
                  result = answer;
                }
              });

              const boldRow = isCorrect(result!);

              const percent = `${getResponseCountInPercent(
                totalResponses,
                Number(this.point.y),
              )}%`;

              if (boldRow) {
                return `<span style="color:${highlightBarColor};font-weight: bold;">${percent}</span>`;
              } else {
                return `<span>${percent}</span>`;
              }
            },
            className: styles.dataLabel,
            style: {
              textOutline: '0',
              fontSize: '12px',
              fontWeight: '700',
            },
          },
          borderWidth: 0,
          animation: false,
        },
      },
      series: responsesWithCountAndColor,

      credits: {
        enabled: false,
      },
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 700,
              minWidth: 651,
            },
            chartOptions: {
              chart: {
                marginLeft: 280,
              },
              xAxis: {
                labels: {
                  x: -280,
                },
              },
            },
          },
          {
            condition: {
              maxWidth: 650,
              minWidth: 621,
            },
            chartOptions: {
              chart: {
                marginLeft: 260,
              },
              xAxis: {
                labels: {
                  x: -260,
                },
              },
            },
          },
          {
            condition: {
              maxWidth: 620,
              minWidth: 521,
            },
            chartOptions: {
              chart: {
                marginLeft: 240,
              },
              xAxis: {
                labels: {
                  x: -240,
                },
              },
            },
          },
          {
            condition: {
              maxWidth: 520,
              minWidth: 371,
            },
            chartOptions: {
              chart: {
                marginLeft: 200,
              },
              xAxis: {
                labels: {
                  x: -200,
                },
              },
            },
          },
          {
            condition: {
              maxWidth: 370,
            },
            chartOptions: {
              chart: {
                marginLeft: 180,
              },
              xAxis: {
                labels: {
                  x: -180,
                },
              },
            },
          },
        ],
      },
    });
  }, [
    answerStats,
    barColor,
    chartColor,
    highlightBarColor,
    responsesWithCountAndColor,
    yAxisMaxCount,
    totalResponses,
    chartInitialized,
    text,
  ]);

  if (!responsesByTeam) {
    return <div>{text.noDataAvailable}</div>;
  }

  return (
    <div className={styles.chartContainer}>
      {!chartInitialized && (
        <div
          className={styles.chartInnerContainer}
          style={{
            height: `${getPointsWidthAndHeight(answerStats.length).height}px`,
          }}
        >
          <LpSpinner message={`${text.initializing}...`} />
        </div>
      )}
      <div
        ref={ref}
        data-testid="questionResultChartForTeam"
        className={styles.chartInnerContainer}
      />
    </div>
  );
};

const getYAxisMaxCount = (maxAnswerCount: number) => {
  if (maxAnswerCount < 10) {
    return maxAnswerCount + 2;
  } else {
    const increasedLength = Math.round(maxAnswerCount * 0.3);
    const lengthOfYAxis = maxAnswerCount + increasedLength;
    return lengthOfYAxis;
  }
};

const isCorrect = (answer: Answer) => {
  return !!answer.isCorrect;
};

const getPointsWidthAndHeight = (answersLength: number) => {
  if (answersLength <= 6) {
    return { pointWidth: 16, height: answersLength * 115 };
  } else if (answersLength <= 12) {
    return { pointWidth: 8, height: answersLength * 55 };
  }
  return { pointWidth: 2, height: answersLength * 35 };
};

const createSeries = (
  responsesByTeam: TeamResponse[],
  answerStats: Answer[],
  teams: ITeam[],
) => {
  if (!responsesByTeam) {
    return;
  }
  const teamResponseCountAndColor: any[] = [];
  teams.forEach(team => {
    const teamResponses = responsesByTeam.filter(
      response => response.teamUid === team.id,
    );

    const teamResponseCountForAnswerIds: { y: number; className: string }[] =
      [];
    answerStats.forEach(answer => {
      if (answer.id === 'NA') {
        return;
      }
      const teamResponsesByAnswerId = teamResponses.filter(
        teamResponse => teamResponse.answerId === answer.id,
      );
      let count = 0;
      if (teamResponsesByAnswerId.length !== 0) {
        count = teamResponsesByAnswerId[0].count;
      }
      teamResponseCountForAnswerIds.push({
        y: count,
        className: answer.isCorrect ? styles.correctBar : styles.incorrectBar,
      });
    });

    const teamWiseResponseCountAndColor = {
      data: teamResponseCountForAnswerIds,
      color: team.color,
      pointWidth: getPointsWidthAndHeight(answerStats.length).pointWidth,
      opacity: 1,
      groupPadding: 0.1,
    };
    teamResponseCountAndColor.push({ ...teamWiseResponseCountAndColor });
  });

  return teamResponseCountAndColor;
};

const getCheckMarkImage = () => {
  const checkmark = `<div style="width: 35px">
    <span class=${styles.checkmark}>        
      <div class=${styles.checkmark_stem}></div>
      <div class=${styles.checkmark_kick}></div>
    </span>
  </div>`;
  return checkmark;
};

const getImage = (answer: Answer) => {
  const imageSrc = answer!.image?.url;
  const showImage = imageSrc ? true : false;

  const image = `<div class=${styles.image}>      
  ${
    imageSrc !== undefined && showImage
      ? `<img src="${imageSrc}" class=${styles.answerImage} height="40px"
               width="60px"/>`
      : ''
  }
  </div>`;
  return image;
};

const getResponseCountInPercent = (
  totalAnswers: number,
  responseCount: number,
): number => {
  if (totalAnswers === 0) {
    return 0;
  }
  return Math.round((responseCount / totalAnswers) * 100);
};
